@import '~styles/variables';
.warning-message {
  width: 100%;
  display: flex;
  align-items: center;
  background-color: $accent-cyan;
  color: $background-100;
  border-radius: 6px;
  flex-direction: row;
  font-size: 14px;
  margin-bottom: 50px;
  min-height: 52px;
  color: $background-100;
  justify-content: space-between;

  &.no-margin {
    margin-bottom: 0px;
  }

  span {
    font-weight: 700;
  }

  .icon-container {
    display: flex;
    align-items: center;
  }
  .warning-message-left {
    display: flex;
    align-items: center;
  }

  .icon-dark {
    margin: 0px 14px;
    fill: $element-300;
  }
  .message-close {
    fill: $background-100;
    margin: 0 14px;
    cursor: pointer;
  }

  @media only screen and (max-width: $breakpoint-tablet) {
    min-height: 72px;
    flex-direction: row;
    margin-bottom: 30px;

    .message {
      display: flex;
      flex-direction: column;
    }
  }

  @media only screen and (max-width: $breakpoint-mobile) {
    top: 140px;
    height: 98px;
  }
}
