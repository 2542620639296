@import '~styles/variables';

.drop-button-container {
  background-color: $background-200;

  &.dropup {
    position: relative;
    display: inline-block;
    .menu {
      top: -110px;
    }
  }

  .btn {
    font-size: 14px;
    padding: 0 12px 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .dropdown-toggle {
      display: flex;
      align-items: center;
    }

    .icon-fill {
      fill: $element-900;
      transition: transform 0.25s;
    }

    .rotate-180 {
      transform: rotate(180deg);
      transition: transform 0.25s;
    }
  }

  .menu {
    border-radius: 4px;
    width: 100%;
    background-color: $element-900;
    margin-top: 5px;
    color: $background-100;
    font-weight: 400;
    text-align: left;
    padding: 0;

    li {
      margin: 4px 0;
      padding: 8px 20px;
      &:hover {
        background-color: $background-700;
      }
    }
  }
}
