@import '~styles/variables';

.container {
  width: 180px;
  min-height: 100%;
  background: $element-750;
  border-radius: 4px;
  padding: 18px 12px;
  display: flex;
  flex-direction: column;
  gap: 10%;

  .section {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .section-title {
      font-size: 12px;
      font-weight: 700;
      color: $background-100;
    }

    .section-inputs {
      display: flex;
      justify-content: space-between;
    }
  }
}
