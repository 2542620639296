@import '~styles/variables';

.stepper {
  display: flex;
  .previous-step {
    display: flex;
    align-items: center;
    color: $element-200;
    margin-right: 40px;
    min-width: 81px;
    &:hover {
      .icon-stepper {
        fill: $accent-blue;
      }
      .previous-text {
        color: $accent-blue;
      }
    }
  }

  .previous-text {
    font-size: 14px;
    font-weight: 400;
    color: $element-200;
  }

  .steps-container {
    display: flex;
    color: $element-200;
    margin-right: 40px;

    .step-container {
      display: flex;
      align-items: center;
      color: $element-200;
      cursor: pointer;
      &.active-step {
        cursor: default;
      }
      .steps-divider {
        width: 15px;
        background-color: $element-200;
        height: 2px;
        border: 0;
        margin: 0 7px;
      }
      .step-number {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        border: 1px solid $element-200;
        margin-right: 5px;
        font-weight: 700;
        font-size: 13px;
      }
      .step-text {
        font-weight: 400;
        font-size: 12px;
      }
    }

    .active-step {
      color: $accent-cyan;

      .step-number {
        color: $background-200;
        background: $accent-cyan;
        border: 1px solid $accent-cyan;
      }
    }
  }

  .icon-stepper {
    margin-right: 5px;
  }

  .next-button {
    width: 110px;
    height: 40px;
    border-radius: 4px;
    background: $accent-cyan;
    border: 0;
    color: $element-900;
    font-size: 14px;
    font-weight: 700;
    margin-left: 5px;
  }

  .next-step {
    display: flex;
    .reset {
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .preview-button {
    height: 40px;
    border-radius: 4px;
    background: transparent;
    border: 1px solid $element-900;
    color: $element-900;
    font-size: 14px;
    font-weight: 700;
    margin-right: 5px;
    display: flex;
    align-items: center;
    gap: 5px;
    &:hover {
      border: 1px solid $accent-blue;
      color: $accent-blue;
      .preview-icon {
        fill: $accent-blue;
      }
    }
  }

  .final-step {
    display: flex;
    gap: 10px;

    .strategy-button {
      display: flex;
      align-items: center;
      gap: 5px;
      height: 40px;
    }

    .strategy-details {
      font-size: 11px;
      line-height: 14.3px;
      margin: 10px;
      color: $element-50;
    }
  }

  @media only screen and (width < $breakpoint-laptop) {
    justify-content: center;
    .step-text {
      display: none;
    }

    .previous-step {
      justify-content: center;
    }

    .previous-text {
      display: none;
    }

    .steps-container {
      margin-right: 5.5px !important;
    }
    .previous-step {
      margin-right: 10.5px;
    }
  }

  @media only screen and (width < $breakpoint-mobile) {
    .next-button {
      width: 80px;
    }

    .previous-step {
      margin-right: 0px;
    }
  }
}
