@import '~styles/variables';

.input {
  width: 50px;
  height: 32px;
  border-radius: 5px;
  border: 1px solid $element-100;
  text-align: center;
  font-size: 11px;
  color: $background-100;
  background: $element-900;

  &:focus {
    outline: none;
  }
}
