@import '~styles/variables';
@import '~styles/text-overflow';

%light {
  color: $element-400;
  font-size: 14px;
}

.crop-season {
  @extend %light;
  display: flex;
  align-items: center;
  flex-shrink: 2;
  min-width: 200px;
  p {
    margin: 0px;
    @include text-overflow;
  }
  .icon {
    margin-right: 12px;
    margin-top: 5px;
  }
}

.variety {
  @extend %light;
  width: 70px;
}

.location {
  @extend %light;
  max-width: 100px;
  @include text-overflow;
}

.modified {
  @extend %light;
  flex-shrink: 0;
  margin-right: 65px;
  align-items: center;
}

@media only screen and (max-width: $breakpoint-tablet) {
  .crop-season {
    display: none;
  }

  .modified {
    display: flex;
    justify-content: flex-end;
    margin-right: 5px;
    flex-shrink: 0;
  }

  .location {
    @include text-overflow;
    margin-right: auto;
  }

  .variety {
    @include text-overflow;
    margin-left: 5px;
    margin-right: auto;
    max-width: 60px;
  }
}

@media only screen and (max-width: $breakpoint-mobile) {
  .location {
    display: none;
  }

  .variety {
    display: none;
  }
}
