@import '~styles/variables';
@import '~styles/text-overflow';

.container {
  position: relative;
  cursor: pointer;

  .title {
    display: flex;
    font-size: 14px;
    justify-content: space-between;
    color: $background-700;
    flex-shrink: 0;
    align-items: center;

    .text {
      @include text-overflow;
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 75%;
      justify-content: center;
    }

    .missing-value {
      color: $element-400;
    }

    .rotate-180 {
      transform: rotate(180deg);
    }
  }

  .content {
    position: absolute;
    bottom: 25px;
    right: 0;
    width: 250px;
    border-radius: 4px;
    box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.35);
    background: $element-900;
    overflow-y: scroll;
    max-height: 285px;
    padding: 10px 0px;

    .option-container {
      display: flex;
      align-items: center;
      height: 50px;
      .option {
        color: $background-100;
        padding: 0 20px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &:hover {
        background: $background-700;
      }
    }

    @media only screen and (max-width: $breakpoint-mobile) {
      width: 100%;
    }
  }
}
