@import '~styles/variables';

.linked-accounts {
  margin-left: 16px;
  color: $element-200;
  font-size: 16px;
  .header {
    display: flex;
    margin-top: 15px;

    span {
      margin-left: 16.5px;
    }
  }

  .title {
    background: $background-500;
    width: calc(100% + 48px);
    margin-left: -24px;
    margin-top: 20px;
    height: 50px;
    display: flex;
    align-items: center;
    padding-left: 24px;
  }

  .content {
    margin-top: 13px;
    display: flex;
    flex-direction: column;
    gap: 25px;

    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;

      :global(.current-id) {
        margin-right: 5px;
      }
    }
  }
}
