@import '~styles/variables';

.container {
  width: 100%;
  height: 385px;
  border-radius: 8px;
  background: $background-500;
  padding: 30px 30px;
  display: flex;
  flex-direction: row;
  gap: 10px;

  .first-column {
    display: flex;
    flex-direction: column;
    gap: 40px;
    flex-shrink: 0;
    width: 300px;
    .switch-container {
      display: flex;
      flex-direction: column;
      gap: 22px;

      .switch-item {
        display: flex;
        align-items: center;
        gap: 10px;

        .switch-plan {
          font-size: 12px;
          font-weight: 700;
        }
      }
    }
  }

  .second-column {
    flex-grow: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

    .graph {
      max-width: calc(100% - 30px);
      height: 80%;
    }
  }
}
