@import '~styles/variables';
@import '~styles/text-overflow';

.card {
  .icon {
    margin-left: 10px;
    display: flex;
    align-items: center;
  }
  .title-container {
    display: flex;
    align-items: center;
    .title {
      margin-left: 10px;
      font-size: 14px;
      font-weight: 700;
      height: 100%;
    }
    .description {
      margin-left: auto;
      font-size: 12px;
      color: $element-400;
      min-width: 140px;
      flex: 1;
    }
    @media only screen and (max-width: $breakpoint-mobile) {
      .description {
        margin-left: 10px;
        margin-right: auto;
        min-width: initial;
        font-size: 12px;
        color: $element-400;
      }
    }
  }
  .input-container {
    width: 100%;
    display: flex;
    min-height: 60px;
    .input {
      width: 100%;
      margin-left: 5px;
      margin-right: 10px;
      background-color: transparent;
      color: $element-900;
      outline: none;
      border: none;
      border-bottom: 1px solid $element-900;
    }
    .not-valid {
      border-bottom: 1px solid $accent-red;
    }
    .confirmations-container {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      margin-right: 5px;
      .actions-icon {
        &:hover {
          fill: $accent-blue;
        }
      }
    }
  }
  .actions {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .actions-icon {
      margin-top: 2px;
    }
  }

  .accordion-generic-card {
    position: relative;
    width: 30px;
    height: 30px;
    text-align: center;

    .accordion-generic-card-body {
      width: 0;
    }

    input:checked ~,
    &[open] {
      & .accordion-generic-card-body {
        z-index: 1;
        width: fit-content;
        position: absolute;
        right: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-radius: 6px;
        box-shadow: 0px 3px 10px rgba(26, 26, 26, 0.15);
        background: $element-900;
        color: $background-100;
        padding: 10px 0px;
        white-space: nowrap;
      }
    }
  }
  @media only screen and (min-width: $breakpoint-tablet) and (max-width: $breakpoint-laptop) {
    min-width: 358px;
    max-width: calc((100vw - 52px) / 2);
  }
  @media only screen and (max-width: $breakpoint-tablet) {
    width: 100%;
  }
}
.light-mode {
  width: 100%;
  color: $background-100;
  background: $element-900;
  .title-container .title {
    width: fit-content;
    font-weight: 400;
    @include text-overflow;
  }

  .input-container {
    .actions-icon {
      fill: $background-400;
    }
    .input {
      color: #000;
    }
  }

  @media only screen and (max-width: $breakpoint-laptop) {
    min-width: 100%;
  }
}
