@import '~styles/variables';

@mixin styledCheckBox($color, $checkmark-color) {
  .legend-input {
    &:checked + :global(.form-icon) {
      background-color: $color;
      border-color: $color;
      .before-element {
        background-clip: padding-box;
        border: 0.1rem solid $checkmark-color;
        border-left-width: 0;
        border-top-width: 0;
        content: '';
        height: 9px;
        left: 50%;
        margin-left: -3px;
        margin-top: -6px;
        position: absolute;
        top: 50%;
        transform: rotate(45deg);
        width: 6px;
      }
    }

    &:hover + :global(.form-icon) {
      background-color: $color;
    }

    &:not(:checked):not(:hover) ~ :global(.info-icon) {
      svg {
        fill: $disabledLines;
      }
    }
    &:not(:checked):hover ~ .text-off {
      color: #fff;
    }
  }
  :global(.form-icon) {
    background-color: $element-200;
  }
}
.info-icon {
  display: flex;
  align-items: center;
  height: 10px;
  margin-left: 10px;
}
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  .legend-item {
    display: flex;
    align-items: center;
    margin-right: 10px;
    &.actuals,
    &.legend-item-disabled,
    &.guardRails,
    &.plan,
    &.adaptivePlan {
      .text {
        min-height: 30px;
        justify-content: center;
        margin-right: 20px;
      }
    }
    &.plan {
      .text {
        margin-right: 5px;
        align-items: center;
        justify-content: center;
      }
    }

    label {
      display: flex;
      align-items: center;
      cursor: pointer;
      :global(.info-icon) {
        height: 23px;
        margin-left: 5px;
      }
    }

    .text {
      margin-top: -2px;
      font-size: 14px;
      display: flex;
      flex-direction: column;
      line-height: 16px;
      margin-left: 5px;
      .text-title,
      .text-label {
        width: 125px;
        overflow: hidden;
        display: inline-block;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .text-off {
      color: $element-200;
    }
  }

  :global(.link-icon) {
    margin-top: 1px;
  }

  .legend-item-disabled {
    .legend-input {
      pointer-events: none;
      &:checked + :global(.form-icon) {
        &::before {
          width: 0px;
          height: 0px;
          border: 0px;
        }
        .before-element {
          display: none;
        }
      }
      &:focus + .form-icon {
        border-color: none;
      }
    }
    .text {
      pointer-events: none;
      color: $element-200;
    }
  }

  .actuals {
    @include styledCheckBox($element-900, $element-200);
  }
  .guardRails {
    @include styledCheckBox($guardrails, $element-900);
  }
  .plan {
    @include styledCheckBox($plan, $element-200);
  }
  .adaptivePlan {
    @include styledCheckBox($adaptivePlan, $element-900);
  }
  .genericLines {
    @include styledCheckBox($genericLines, #576776);
  }
}
.container {
  .legend-item {
    &.text-full {
      .text {
        .text-title,
        .text-label {
          width: auto;
        }
      }
    }
    &.legend-item-disabled {
      .text {
        margin-right: 5px;
      }
    }
  }
}
