@import '~styles/variables';

.EC-row {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.EC-error {
  color: $accent-red;
  margin-top: 30px;
  text-align: center;
  margin-bottom: 20px;
}
