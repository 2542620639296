@import '~styles/variables';

.file-list-container {
  width: 558px;
  height: 150px;
  background: $background-500;
  border-radius: 4px;
  padding: 2.5px 0px;
  overflow-y: auto;

  .file-list-item {
    cursor: pointer;
    display: flex;
    height: 24px;
    color: $element-900;
    font-size: 12px;
    align-items: center;
    margin-bottom: 5px;
    padding: 12px 5px;

    &:hover {
      background: $element-100;
      border-radius: 4px;
    }

    span {
      margin-left: 8px;
    }
  }

  .active-list-item {
    background: $element-100;
    border-radius: 4px;
  }

  @media only screen and (width > $breakpoint-tablet) and (max-width: $breakpoint-laptop) {
    width: 543px;
  }

  @media only screen and (width< $breakpoint-tablet) {
    width: 288px;
  }
}
::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: $transparent-900;
  border-radius: 23px;
}

::-webkit-scrollbar-thumb {
  background: $background-800;
  max-height: 5px !important;
  border-radius: 23px;
}
