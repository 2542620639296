@import '~styles/variables';
.my-plans-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0px 27px 0px 27px;

  @media only screen and (min-width: $breakpoint-tablet) and (max-width: $breakpoint-laptop) {
    margin: 0px 20px 0px 20px;
  }

  @media only screen and (max-width: $breakpoint-tablet) {
    margin: 0px 16px 0px 16px;
  }
}
.my-plans-header {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  flex-wrap: wrap;
  .selected-folder-header-container {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .selected-folder-title {
      font-size: 36px;
      margin-left: 20px;
      font-weight: bold;
      transform: translateY(-3px);
      @media only screen and (max-width: $breakpoint-mobile) {
        margin-right: 20%;
        margin-top: 23px;
        margin-bottom: 25px;
        margin-left: 15px;
      }
    }

    .my-plans-create-button {
      background-color: $accent-cyan;
      transform: translateY(2px);
      border-radius: 4px;
      height: 40px;
      display: flex;
      align-items: center;
      &-icon {
        margin-left: 19px;
      }
      &-text {
        margin-right: 15px;
        font-weight: bold;
        font-size: 14px;
      }
    }
    .create-plan-link {
      margin-left: auto;
      color: inherit;
      text-decoration: inherit;
      @media only screen and (max-width: $breakpoint-mobile) {
        margin-right: 100%;
      }
    }
  }

  .my-page-page-name {
    display: none;
    margin: 0;
    color: $element-400;
    font-size: 12px;
    margin-top: 20px;
  }

  .my-plans-title-container {
    flex-shrink: 0;
    display: flex;
    margin-right: auto;
    align-items: center;
    .my-plans-title {
      font-size: 36px;
      font-weight: bold;
      line-height: 46.7px;
    }
    .my-plans-info-popup {
      width: 20px;
      height: 20px;
      margin-left: 7px;
      transform: translateY(-10px);
    }
  }
  .my-plans-filters-and-actions {
    display: flex;
    gap: 20px;
    margin-left: auto;
    flex-wrap: wrap;
    .my-plans-datepicker {
      display: flex;
      gap: 10px;
      min-width: 200px;
      @media only screen and (max-width: $breakpoint-tablet) {
        width: 100%;
      }
    }
    .my-plans-dropdown {
      min-width: 243px;
      flex: 1;
      @media only screen and (max-width: $breakpoint-mobile) {
        width: 100%;
      }
    }
    .my-plans-create-button {
      background-color: $accent-cyan;
      border-radius: 4px;
      height: 40px;
      display: flex;
      align-items: center;
      &-icon {
        margin-left: 19px;
      }
      &-text {
        margin-left: 15px;
        margin-right: 15px;
        font-weight: bold;
        font-size: 14px;
      }
    }
    .create-plan-link {
      color: inherit;
      text-decoration: inherit;
    }
  }

  @media only screen and ((max-width: $breakpoint-tablet) and (min-width: $breakpoint-mobile)) {
    flex-direction: column;
    align-items: flex-start;
    .my-page-page-name {
      display: inherit;
    }

    .my-plans-title-container {
      margin-bottom: 15px;
      margin-top: 10px;

      .my-plans-title {
        font-size: 23px;
        line-height: 0px;
      }
      .my-plans-info-popup {
        width: 17px;
        height: 17px;
        transform: translateY(1px);
        margin-left: 5px;
      }
    }
    .my-plans-filters-and-actions {
      margin-bottom: 28px;

      display: flex;
      width: 100%;

      .my-plans-datepicker {
        width: 216px;
      }
      .my-plans-dropdown {
        max-width: 243px;
      }
      .create-plan-link {
        order: 3;
      }
    }
  }
  @media only screen and (max-width: $breakpoint-laptop) {
    margin-bottom: 20px;
  }
}

.my-plans-folders-container {
  padding-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  @media only screen and (min-width: $breakpoint-tablet) and (max-width: $breakpoint-laptop) {
    gap: 12px;
  }
  @media only screen and (max-width: $breakpoint-tablet) {
    gap: 10px;
  }
}
.light-mode {
  gap: 10px;
}
