@import '~styles/variables';

.navbar {
  background-color: $background-200;
  height: $navBarHeight;
  display: flex;

  .menu-container {
    order: 1;
    margin-left: 20px;
  }

  .page-title-container {
    order: 2;
    flex-grow: 0.5;
  }

  .logo-header-container {
    order: 3;
  }

  .profile-header-container {
    order: 4;
    justify-self: flex-end;
    margin-left: auto;
  }

  .icon-logo {
    width: 30px;
    height: 30px;
  }

  .page-title {
    margin: 0;
    font-size: 14px;
    color: $element-500;
  }

  .hamburger-container {
    z-index: 900;
    margin-right: 20px;
    transform: translateY(5px);
  }

  .logo-header {
    display: none;
    display: flex;
    align-items: center;
    padding: 0 10px;
    position: relative;
    left: 50%;

    div {
      margin-left: 7.5px;
      overflow: hidden;
      white-space: nowrap;
      span {
        display: inline-block;
        font-weight: 100;
        margin-left: 1px;
      }
    }

    .avatar {
      min-width: 30px;
      height: 30.2px;
    }
    @media only screen and (max-width: $breakpoint-laptop) {
      left: 2%;
    }
  }

  @media only screen and (max-width: $breakpoint-laptop) {
    .profile-header,
    .page-title-container {
      display: none !important;
    }
    .logo-header-container,
    .hamburger-container {
      display: inherit !important;
    }
    .hamburger-container {
      &:hover {
        .icon-fill {
          fill: $accent-cyan;
          cursor: pointer;
        }
      }
    }

    .menu-container {
      order: 3;
      transform: translateY(-5px);
      margin-left: 0px;
    }

    .logo-header-container {
      order: 1;
    }
    .profile-header-container {
      order: 2;
    }
  }

  .profile-header {
    height: 40px;
    margin-right: 30px;
  }

  .notification {
    margin-right: 20px;
  }
}
