@import '~styles/variables';
@import '~styles/text-overflow';

.multiselect-dropdown-container {
  width: 100%;

  .form-checkbox {
    width: 100%;
    cursor: pointer;

    .text {
      font-size: 14px;
      margin-left: 10px;
    }
  }

  .dropdown {
    width: 100%;
    height: 40px;
    border-radius: 4px;
    border: 1px solid $background-700;

    &.active .dropdown-button {
      background: $background-100;
    }

    .dropdown-button {
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0px 10px;
      background: $background-500;

      border-radius: 4px;
      color: $background-700;
      font-size: 14px;
      font-weight: 400;
      .icon-svg {
        fill: $background-700;
      }

      .preview {
        max-width: 80%;
        @include text-overflow;
      }
    }

    .menu {
      padding: 0px;
    }

    .dropdown-menu {
      margin-top: 10px;
      background: #fff;
      width: 100%;
      color: $background-200;
      border-radius: 6px;
      box-shadow: 0px 3px 10px rgba(26, 26, 26, 0.15);
    }

    .dropdown-item {
      width: 100%;
      height: 40px;
      color: $background-200;
      display: flex;
      align-items: center;
      padding-left: 20px;

      .text {
        display: block;
        width: 99%;
        @include text-overflow;
        padding-right: 2px;
      }

      &:hover {
        background: $background-700;
      }
      margin-top: 0px;
    }

    .rotate-180 {
      transform: rotate(180deg);
      transition: transform 0.25s;
    }
    .dropdown-icon {
      transform: rotate(0deg);
      transition: transform 0.25s;
    }
  }

  .dropdown-title {
    font-size: 12px;
    color: $element-400;
    margin-bottom: 5px;
    &.required {
      &:after {
        content: '*';
        color: $accent-red;
      }
    }
  }

  .required-text {
    margin-top: 5px;
    color: $accent-red;
    font-size: 11px;
  }
}
