@font-face {
  font-family: 'Helvetica Neue LT Pro';
  src: url('./fonts/HelveticaNeueLTPro-Th.woff2') format('woff2'),
    url('./fonts/HelveticaNeueLTPro-Th.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Neue LT Pro';
  src: url('./fonts/HelveticaNeueLTPro-Roman.woff2') format('woff2'),
    url('./fonts/HelveticaNeueLTPro-Roman.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Neue LT Pro';
  src: url('./fonts/HelveticaNeueLTPro-Bd.woff2') format('woff2'),
    url('./fonts/HelveticaNeueLTPro-Bd.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
