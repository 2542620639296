@import '~styles/variables';

.container {
  input:-webkit-autofill,
  input:-webkit-autofill:hover {
    -webkit-box-shadow: 0 0 0 30px $background-500 inset;
    -webkit-text-fill-color: white;
    caret-color: white;
  }

  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px $background-100 inset;
    -webkit-text-fill-color: white;
    caret-color: white;
  }

  width: 60%;
  border-radius: 8px;

  .title {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 30px;
  }

  .profile-save-changes {
    margin-top: 20px;
    height: 40px;
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: center;

    gap: 30px;
    .phone-input {
      width: 100%;
      input {
        background-color: $background-500;
        color: $accent-light-blue;
        font-size: 14px;
        padding: 0 0 2px 0;
        outline: 0;
        border: 0;
        &:focus {
          outline: none;
          background-color: $background-100;
        }
      }
    }
    .first-column {
      width: 48%;

      @media only screen and (width <= $breakpoint-mobile) {
        width: 100%;
      }
    }

    .second-column {
      width: 48%;
      margin-top: 0;
      padding-bottom: 10px;

      @media only screen and (width <= $breakpoint-mobile) {
        width: 100%;
      }
    }

    @media only screen and (width <= $breakpoint-mobile) {
      flex-direction: column;
      gap: 0px;
    }

    .second-column > div:first-child {
      margin-bottom: 15px;
    }
  }

  @media only screen and (width <= $breakpoint-tablet) {
    width: 100%;
    padding: 0 20px;
  }
  @media only screen and (width <= $breakpoint-mobile) {
    height: 100%;
    padding: 0 20px;
  }
}
