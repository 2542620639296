@import '~styles/variables';

.main-container {
  flex: 0;
  padding-right: 30px;
  font-size: 14px;

  .horizontal-divider {
    border: 1px solid $background-500;
  }

  .menu-links {
    overflow: hidden;
    margin: 0;

    .menu-item {
      list-style-type: none;
    }

    .accordion-body {
      display: none;
    }

    .accordion-links {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      input:checked ~,
      &[open] {
        & .accordion-header > {
          .rotate-180 {
            transform: rotate(180deg);
          }
        }
        & .accordion-body {
          display: block;
        }
      }

      .accordion-header {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: bold;
        font-size: 14px;
        color: $element-900;
        margin: 10px;
        padding: 5px 0px;
      }
      .content-pruning,
      .content-stem-density,
      .content-co2,
      .content-first-flowering,
      .content-artificial-lights,
      .content-temperature-strategy,
      .content-ec-irrigation,
      .content-ripening {
        width: 100%;
        height: 100%;
      }
      .toggle-container {
        width: 100%;
        display: flex;
        margin: 27px 0px 0px 0px;
        justify-content: space-between;
      }
      .content-temperature-strategy {
        padding-top: 40px;
      }
    }
  }
}
