@import '~styles/variables';
@import '~styles/text-overflow';

.plansContent {
  width: 100%;
  table {
    width: 100%;
    border: none;
    border-spacing: 0 10px;

    tr {
      border-bottom: 10px solid $element-900;
    }

    th:not(:last-child) {
      min-width: 80px;
      resize: horizontal;
      overflow: auto;
      text-align: left;
    }

    tbody {
      td {
        min-height: 62px;
        &:first-child {
          border-left-style: solid;
          border-left-width: 0px;
          border-end-start-radius: 8px;
          border-start-start-radius: 8px;
        }
        &:last-child {
          border-right-style: solid;
          border-right-width: 0px;
          border-start-end-radius: 8px;
          border-end-end-radius: 8px;
        }
      }
    }
  }
}

.headers {
  font-weight: 700;
  color: $element-400;
  font-size: 12px;

  .location {
    margin-right: auto;
    @include text-overflow;
  }

  .plan-name {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
    margin-left: 15px;
    cursor: pointer;
  }

  .variety {
    margin-right: auto;
  }

  .crop-season {
    min-width: 200px;
    margin-right: auto;
  }

  .modified {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
    cursor: pointer;
  }

  @media only screen and (max-width: $breakpoint-tablet) {
    .crop-season {
      display: none;
    }

    .modified {
      padding-right: 50px;
    }

    .variety {
      padding-left: 10px;
    }
  }

  @media only screen and (max-width: $breakpoint-mobile) {
    .location {
      display: none;
    }

    .variety {
      display: none;
    }

    .modified {
      display: none;
    }

    .plan-name {
      display: none;
    }
  }
}
