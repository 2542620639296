@import '~styles/variables';

.input-container {
  display: flex;
  flex-direction: column;
  color: $element-400;
  font-size: 12px;
  margin-bottom: 10px;

  .input-label {
    margin-bottom: 8px;

    &.input-required {
      &:after {
        content: '*';
        color: $accent-red;
      }
    }
  }
  .input {
    width: 100%;
    height: 40px;
    background-color: $background-500;
    border: 1px solid $background-700;
    border-radius: 4px;
    color: $background-700;
    padding: 14px 10px;
    font-size: 14px;

    &:focus {
      outline: none;
      background-color: $background-100;
    }
  }
  .PhoneInput--focus {
    background-color: $background-100;
  }

  .error-text {
    display: block;
    height: 10px;
    margin-top: 5px;
    color: $accent-red;
  }

  .disabled {
    color: #576776;
    border: 1px solid $element-100;
  }
}
