@import '~styles/variables';
@import '~styles/mixins';

.container {
  @include page-container;
  .header {
    @include page-header;
    @media only screen and (min-width: $breakpoint-laptop) and (max-width: $breakpoint-notifications-filter) {
      flex-direction: column;
      align-items: flex-start;
    }

    .title {
      @include page-title;
    }
  }
}
