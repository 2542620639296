@import '~styles/variables';
@import '~styles/spectre/variables';

$footerHeight: 120px;

.plan-settings-view-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .plan-settings-footer {
    width: 100%;
    height: $footerHeight;
    background: $background-200;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: auto;
    &.footer-fixed {
      position: fixed;
      bottom: 0;
      z-index: $zindex-5;
      transition: width 0.1;
      transform: translate3d(0, 0, 0);
    }
  }

  .plan-settings-view {
    display: flex;
    flex-direction: column;
    margin: 20px 26px 0px 28px;
    justify-content: flex-start;

    .plan-settings-title {
      font-size: 32px;
      font-weight: 700;
      display: flex;
      align-items: center;
      gap: 5px;
      margin-bottom: 52px;
      justify-content: space-between;
      .plan-settings-title-content {
        display: flex;
        align-items: center;
        .icon-light {
          margin-top: -10px;
        }
      }
      .icon-light {
        margin-left: 5px;
      }
    }

    .plan-settings-details {
      font-size: 11px;
      line-height: 14.3px;
      margin: 10px;
      color: $element-50;
    }

    .plan-settings-content {
      .new-plan-btn {
        display: flex;
        align-items: center;
        gap: 5px;
      }

      &.footer-fixed {
        padding-bottom: $footerHeight;
      }

      .step-title {
        font-size: 36px;
        margin-bottom: 50px;
        display: flex;
        .icon-light {
          margin-left: 5px;
          margin-top: 13px;
        }
      }

      .step-content {
        display: flex;
        justify-content: center;
        flex-grow: 0;
      }
    }

    .stepper-mobile-container {
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      .stepper-mobile {
        margin-bottom: 30px;
      }
      .stepper-mobile {
        @media only screen and (width > $breakpoint-mobile) {
          display: none;
        }
      }
    }

    @media only screen and (max-width: $breakpoint-mobile) {
      margin: 46px 16px 0px 16px;

      .plan-settings-title {
        width: fit-content;
        height: fit-content;
        font-size: 23px;
        margin-bottom: 20px;
      }

      .plan-settings-details {
        width: 100%;
        margin-bottom: 20px;
        height: fit-content;
      }

      .plan-settings-content {
        min-height: 160px;
      }
    }

    @media only screen and (width > $breakpoint-mobile) and (width < $breakpoint-laptop) {
      .plan-settings-details {
        width: 100%;
      }
    }
  }
}
