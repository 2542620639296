@import '~styles/spectre/variables';
@import '~styles/variables';

.sidebar-mobile {
  .off-canvas {
    width: 0%;
    height: 0%;
    z-index: -1;
    transition: z-index 0.3s;
  }

  .mobile {
    background: $bg-color;
    bottom: 0;
    min-width: 10rem;
    top: 0;
    transition: transform 0.25s;
    z-index: $zindex-2;
    @if $rtl == true {
      right: 0;
      transform: translateY(100%);
    } @else {
      left: 0;
      transform: translateY(-100%);
    }
  }

  &.hide-off-canvas {
    display: none;
  }

  &.display-off-canvas {
    z-index: 1000 !important;

    .overlay {
      z-index: 1;
      position: fixed;
      left: 0;
      right: 0;
      width: 100vw;
      height: 100vh;
      background: rgba($background-100, 0.8);
      backdrop-filter: blur(2px);
    }
  }
}
