@import '~styles/variables';

.notifications {
  height: calc(100% - 85px);
  overflow-y: auto;
  overflow-x: hidden;
  @media screen and (max-width: $breakpoint-laptop) {
    height: calc(100% - 40px);
  }
}

.notification-list-group {
  margin: 0;
}

.notification-list-title {
  font-size: 11px;
  font-weight: bold;
  color: $accent-light-blue;
  margin: 10px 0;
}
.notification-list-footer {
  text-align: center;
  color: $element-100;
  font-size: 14px;
  padding: 30px 10px;
}

.notifications {
  position: relative;
  .notification-list-container {
    width: 100%;
    transition: width 0.3s ease;
    height: 100%;
    overflow: auto;
    border: 1px solid transparent;
    transition: border-color 0.2s ease;
    padding: 10px;
    border-radius: 4px;
  }
  .notification-details {
    width: 0;
    transition: width 0.3s ease;
    transition: left 0.3s ease;

    left: 100%;
  }
  &.has-side-view {
    display: flex;
    .notification-list-container {
      border: 1px solid #576776;
      width: 50%;
    }
    .notification-details {
      width: 50%;
      left: 50%;
      position: absolute;
    }
  }

  &.no-side-view {
    display: flex;
    .notification-list-container {
      width: 100%;
    }
    .notification-details {
      width: 0;
      left: 100%;
      position: absolute;
    }
  }
}
