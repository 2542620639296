//Background
$background-100: #132a37;
$background-200: #233545;
$background-400: #34414d;
$background-500: #394958;
$background-600: #576776;
$background-700: #e7f0f3;
$background-800: #d9d9d9;
$background-900: #b6bfc9;
$transparent-900: #d9d9d966;
//Accent
$accent-red: #fb424d;
$accent-orange: #ff8947;
$accent-magenta: #e090fc;
$accent-cyan: #00bcff;
$accent-blue: #0f8cd3;
$accent-light-green: #00ffd1;
$accent-light-blue: #e7f0f3;
$accent-light-gray: #f4f4f4;
//Element
$element-900: #fff;
$element-800: #f7f7f7;
$element-750: #f4f4f4;
$element-700: #e5e5e5;
$element-600: #afafaf;
$element-500: #919aa2;
$element-400: #8293a6;
$element-300: #231f20;
$element-200: #8293a6;
$element-100: #576776;
$element-50: #1a1a1a;
$element-40: #132a37;
//Gradients
$gradient-magenta: linear-gradient(
  180deg,
  rgba(227, 84, 163, 0.53) 0%,
  rgba(227, 84, 163, 0.16) 96.85%
);
$gradient-accent-orange: linear-gradient(
    180deg,
    rgba(255, 148, 50, 0.3) 0%,
    rgba(255, 136, 70, 0.027) 100%
  ),
  #233545;

//Breakpoints
$breakpoint-laptop: 1024px;
$breakpoint-laptop-large: 1440px;
$breakpoint-tablet: 768px;
$breakpoint-mobile: 425px;
$breakpoint-cards-2-columns: 1460px;
$breakpoint-svg-large-screen: 1833px;
$breakpoint-card-large-screen: 2000px;
$breakpoint-card-Xlarge-screen: 2000px;
$breakpoint-notifications-filter: 1200px;

//Component Sizes
$sidebarOpenedWidth: 240px;

//Top Navigation Bar - Navbar
$navBarHeight: 70px;

//Graph elements
$guardrails: #00cb49;
$plan: #00ffd1;
$adaptivePlan: #79bfff;
$genericLines: #8293a6;
$disabledLines: #8293a6;
