@import '~styles/variables';
$thumb-size: 30px;

@mixin track-styles {
  appearance: none;
  background: transparent;
  border: transparent;
}

@mixin thumb-styles {
  appearance: none;
  pointer-events: all;
  width: $thumb-size;
  height: $thumb-size;
  border-radius: 0px;
  border: 0 none;
  cursor: col-resize;
  background-color: #00bcff;

  &:active {
    cursor: col-resize;
  }
}

.trs-wrapper {
  position: relative;
  align-items: center;
  margin: 0 auto;
  padding-top: 35px;
  z-index: 0;
}
.trs-analyze {
  padding-left: 90px;
  padding-right: 90px;
  padding-bottom: 70px;
  @media only screen and (max-width: $breakpoint-laptop) {
    padding-left: 20px;
    padding-right: 20px;
    overflow: hidden;
    padding-top: 40px;
  }
}

.trs-comparison {
  padding-left: 82px;
  padding-right: 110px;
  padding-bottom: 20px;
}

.trs-control-wrapper {
  display: flex;
  height: 30px;
  position: relative;
}

.trs-input-wrapper {
  width: 100%;
  margin: 0;
  position: absolute;
  height: $thumb-size;
  width: calc(100% + $thumb-size / 2);
}

.trs-slider-button {
  width: $thumb-size;
  z-index: -1;
  position: relative;
}

.trs-input {
  position: absolute;
  width: 100%;
  pointer-events: none;
  appearance: none;
  height: 100%;
  opacity: 0;
  z-index: 3;
  padding: 0;

  &::-ms-track {
    @include track-styles;
  }

  &::-moz-range-track {
    @include track-styles;
  }

  &:focus::-webkit-slider-runnable-track {
    @include track-styles;
  }

  &::-ms-thumb {
    @include thumb-styles;
  }

  &::-moz-range-thumb {
    @include thumb-styles;
  }

  &::-webkit-slider-thumb {
    @include thumb-styles;
  }
}

.trs-rail {
  position: absolute;
  width: 100%;
  width: calc(100% + $thumb-size / 2);
  padding: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 30px;
  border-radius: 3px;
  background: $background-600;
  overflow: hidden;
}

.trs-inner-rail {
  position: absolute;
  height: 100%;
  border: 2px solid #00bcff;
  border-radius: 3px;
  z-index: 10;
  cursor: grab;
}

.isDragging .trs-inner-rail {
  cursor: grabbing;
}

.trs-control {
  width: $thumb-size;
  height: $thumb-size;
  position: absolute;
  background: #00bcff;
  border-radius: 3px;
  top: 50%;
  margin-left: calc(#{$thumb-size} / -2);
  transform: translate3d(0, -50%, 0);
  z-index: 2;
  &.trs-control-left {
    margin-left: 0;
  }

  &.trs-control-right {
    margin-right: 0;
  }

  .trs-control-info {
    display: block;
    position: absolute;
    min-width: 100px;
    font-weight: 700;
    font-size: 11px;
    text-align: center;
    top: 40px;
    left: -36px;
    .trs-control-info-hour {
      display: block;
    }
    .trs-control-info-day {
      display: block;
    }
  }
}
