@import '~styles/variables';

.chip-filter-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  .chip {
    .dot {
      width: 9px;
      height: 9px;
      border-radius: 50%;
      background-color: $accent-cyan;
      margin-right: 4px;
    }

    .close-icon {
      width: 18px;
      height: 18px;
      fill: $element-900;
      display: block;
      cursor: pointer;

      &:hover {
        fill: $accent-cyan;
      }
    }
  }

  .clean-button {
    background-color: transparent;
    border: 1px solid $element-400;
    color: $element-400;
    border-radius: 100px;
    padding: 1px 10px;
    font-size: 14px;
    cursor: pointer;

    &:hover {
      border-color: $accent-cyan;
      color: $accent-cyan;
    }
  }
}
