@import '~styles/variables';
.generic-item {
  font-weight: 400;
  font-size: 14px;
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0 20px;

  &:hover {
    background: $background-700;
  }
}

.plan-folder-link-cropseason {
  margin: 20px 40px;
  .dropdown {
    max-width: 380px;
  }
}
