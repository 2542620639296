// Tooltips
.tooltip {
  position: relative;
  &::after {
    background: #fff;
    border-radius: $border-radius;
    bottom: 100%;
    color: #000;
    content: attr(data-tooltip);
    display: block;
    font-size: $font-size-sm;
    left: 50%;
    max-width: $control-width-sm;
    opacity: 0;
    overflow: hidden;
    padding: $unit-1 $unit-2;
    pointer-events: none;
    position: absolute;
    text-overflow: ellipsis;
    transform: translate(-50%, $unit-2);
    transition: opacity 0.2s, transform 0.2s;
    white-space: pre;
    z-index: $zindex-3;
  }
  &:focus,
  &:hover {
    &::after {
      opacity: 1;
      transform: translate(-50%, -$unit-1);
    }
  }
  &[disabled],
  &.disabled {
    pointer-events: auto;
  }

  &.tooltip-right {
    &::after {
      bottom: 50%;
      left: 100%;
      transform: translate(-$unit-1, 50%);
    }
    &:focus,
    &:hover {
      &::after {
        transform: translate($unit-1, 50%);
      }
    }
  }

  &.tooltip-bottom {
    &::after {
      bottom: auto;
      top: 100%;
      transform: translate(-50%, -$unit-2);
    }
    &:focus,
    &:hover {
      &::after {
        transform: translate(-50%, $unit-1);
      }
    }
  }

  &.tooltip-left {
    &::after {
      bottom: 50%;
      left: auto;
      right: 100%;
      transform: translate($unit-2, 50%);
    }
    &:focus,
    &:hover {
      &::after {
        transform: translate(-$unit-1, 50%);
      }
    }
  }
}
