@import '~styles/variables';

.save-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 25px;
  width: fit-content;
  width: 100%;

  .step-title {
    font-size: 36px;
    margin-bottom: 20px;
  }

  .input-container {
    @media only screen and (width > $breakpoint-tablet) and (max-width: $breakpoint-laptop) {
      width: 543px;
    }

    @media only screen and (width< $breakpoint-tablet) {
      width: 288px;
    }
  }

  .file-list-title {
    display: block;
    font-size: 12px;
    color: $element-500;
    margin: 5px 0px;
  }

  .error-text {
    display: block;
    height: 7px;
    color: $accent-red;
    font-size: 11px;
  }

  .bottom-section {
    width: 558px;

    .new-folder {
      display: flex;
      align-items: center;
      font-size: 12px;
      margin-top: 20px;
      cursor: pointer;

      .icon-fill {
        margin: 0 8px 3px 0;
        fill: $element-800;
      }
    }
  }
}
