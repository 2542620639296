@import '~styles/variables';
@import '~styles/icons';

.strategies-container {
  background-color: white;
  width: 100%;
  color: #132a37;
  overflow-x: auto;
  font-weight: 400;
  font-size: 12px;
  line-height: 26px;
  height: 100%;
}
.strategies-table {
  width: 100%;
  padding: 10px;
  border-collapse: collapse;
  border-spacing: 0;
  height: 100%;
  tr {
    width: 100%;
    display: inline-table;
  }
  td,
  th {
    padding: 2px 20px;
  }
  border-top: 1px solid $background-700;
}

.cell-plan-name {
  text-align: left;
  &th {
    width: 100%;
  }
}
.cell-plan-yield {
  width: 180px;
}
.cell-plan-kgs,
.cell-plan-avg-brix,
.cell-plan-row-select,
.cell-plan-avg-weight {
  width: 130px;
}

.cell-plan-row-select {
  span {
    padding: 0;
    display: flex;
    align-items: center;
  }
}

.cell-plan-preview {
  width: 60px;
}
.cell-plan-compare {
  width: 170px;
  text-align: center;
}

.sorting-icon {
  position: absolute;
  top: 0px;
  left: -25px;
}
.cell-item {
  position: relative;
  line-height: 18px;
}
.cell-sorter {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  cursor: pointer;
  padding: 10px;
  padding-left: 30px;
  height: 50px;
  margin-left: -40px;
  &:hover {
    background-color: $background-700;
    border-radius: 4px;
  }
  .sorter-icon {
    margin-left: 5px;
    width: 10px;
    height: 10px;
    fill: #8293a6;
  }
}
.strategies-table-header {
  height: 65px;
  font-weight: bold;
  display: flex;
  align-items: center;
  .cell-item {
    text-align: left;
  }
  .cell-plan-row-select {
    width: 140px;
  }
  .cell-plan-compare {
    text-align: right;
    .cell-item {
      text-align: right;
      padding: 0 5px;
    }
  }
}

.strategies-table-body {
  overflow-y: auto;
  height: 100%;
  display: block;
}
.strategy-preview {
  cursor: pointer;
}
.even-row {
  background-color: #f4f4f4;
}

.preview-icon {
  display: block;
  fill: #8293a6;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}
