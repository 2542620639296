@import '~styles/variables';

.dropdown-container {
  width: 100%;

  &.dropdown-disabled {
    pointer-events: none;
    .dropdown {
      background: $background-500;
      border: 1px solid $element-100;
      .dropdown-text-container {
        color: $element-100;
      }
      .dropdown-icon {
        fill: $background-600;
      }
    }
  }

  .dropdown {
    width: 100%;
    height: 40px;
    border-radius: 4px;
    border: 1px solid $background-700;

    &.active .dropdown-button {
      background: $background-100;
    }

    .dropdown-button {
      height: 100%;
      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse;
      align-items: center;
      padding: 0px 10px;
      background: $background-500;

      border-radius: 4px;
      color: $background-700;

      font-size: 14px;
      font-weight: 400;
      .icon-svg {
        fill: $background-700;
        flex-shrink: 0;
      }

      .dropdown-text-container {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .light {
      background: $element-900;
      color: $background-100;
    }

    &.active .light {
      background: $element-900;
      color: $background-100;
    }

    .menu {
      padding: 0px;
      max-height: 300px;
    }

    .dropdown-menu {
      margin-top: 10px;
      background: #fff;
      width: 100%;
      color: $background-200;
      border-radius: 6px;
      box-shadow: 0px 3px 10px rgba(26, 26, 26, 0.15);
      padding: 10px;

      input {
        border: 1px solid $background-700;

        &:active,
        &:focus {
          outline: none;
          border-color: $background-600;
        }
      }
    }

    .list-item-container {
      display: flex;
      align-items: center;
      width: 100%;
      .remove-default-icon {
        display: none;
      }
      .is-default-icon {
        display: block;
      }
      .dropdown-icon {
        fill: #8293a6;
      }
      &:hover {
        .remove-default-icon {
          display: block;
        }
        .is-default-icon {
          display: none;
        }
      }
      .dropdown-item-value {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      .dropdown-item-default {
        opacity: 0;
        display: inline-flex;
        position: absolute;
        right: 15px;
        &.is-default {
          opacity: 1;
        }
      }

      &:hover {
        background: $background-700;
        .dropdown-item-default {
          opacity: 1;
        }
      }
      .dropdown-item {
        margin: 5px 0;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: $background-200;
        font-size: 14px;
        display: flex;
        &.hasPostPadding {
          margin-right: 30px;
        }

        .pre-padding {
          width: 8px;
          height: 1rem;
          overflow: hidden;
          display: inline-flex;
          justify-content: center;

          &.visible {
            width: 20px;
            margin: 0 8px 0 12px;
            flex: none;
          }
        }
      }
    }

    .rotate-180 {
      transform: rotate(180deg);
      transition: transform 0.25s;
    }
    .dropdown-icon {
      transform: rotate(0deg);
      transition: transform 0.25s;
    }

    .icon-light-mode {
      fill: $background-100;
    }
  }

  .light {
    border-color: $element-100;
  }

  .dropdown-title {
    font-size: 12px;
    color: $element-400;
    margin-bottom: 5px;
    &.required {
      &:after {
        content: '*';
        color: $accent-red;
      }
    }
  }

  .info-text {
    margin-top: 5px;
    font-size: 11px;
    height: 11px;

    .required-text {
      color: $accent-red;
    }

    .prefilled-text {
      color: $accent-cyan;
    }
  }
}

.modal-container {
  .plan-folder-link-cropseason {
    padding: 20px 40px;
    .dropdown-container {
      min-height: 40px;
    }
    //Dropdown list shows up above the modal
    .dropdown {
      position: fixed;
      max-width: 380px;
    }
  }
}
