@import '~styles/variables';

.track-card {
  background: $background-200;
  border: 0px;
  box-shadow: 0px 3px 35px rgba(2, 28, 43, 0.27);
  border-radius: 8px;
  border-right: 8px solid;

  @media only screen and (width > $breakpoint-mobile) and (width < $breakpoint-tablet) {
    width: 100%;
  }

  @media only screen and (max-width: $breakpoint-mobile) {
    height: 70px;
    display: flex;
    flex-direction: row !important;
    align-items: center;
    padding: 0px 12.5px;

    .track-card-header {
      padding: 0px !important;
      .track-card-header-icon {
        display: none;
      }
      .track-card-title {
        display: none;
      }
    }

    .track-card-body {
      display: flex;
      flex-direction: column !important;
      justify-content: center;
      padding: 0px !important;
      margin-left: 25px;

      .card-title-mobile {
        font-size: 16px;
        font-weight: 700;
        color: $element-900;
      }

      .card-details-mobile {
        font-size: 11px;
        color: $element-400;
        margin-top: 5px;
      }
      .track-card-value {
        display: none !important;
      }
    }

    .track-card-footer-content {
      display: none;
    }

    .track-card-footer-graph {
      height: 50px;
      width: 100px;
    }
  }

  .track-card-header {
    display: flex;
    font-size: 18px;
    font-weight: 700;
    color: $element-400;

    .track-card-title {
      margin-left: 5px;
    }

    .icon-container {
      flex-grow: 3;
      display: flex;
      justify-content: flex-end;
      .track-ideal-icon {
        fill: white;
      }
      .track-danger-icon {
        fill: $accent-orange;
      }
    }
  }

  .track-card-body {
    display: flex;
    flex-direction: row;

    .track-card-value {
      color: $element-900;
      display: flex;
      align-items: flex-end;
      width: 50%;

      font-size: 56px;
      font-weight: 100;

      span {
        font-size: 14px;
        margin-bottom: 15px;
        font-weight: 400;
      }
    }

    .text-value {
      font-size: 25px;
      padding-bottom: 15px;
      font-weight: 400;
    }

    .track-card-graph {
      .recharts-wrapper {
        cursor: inherit !important;
      }

      height: 103px;
      width: 50%;
      margin-bottom: 15px;
      padding-top: 20px;
    }
  }

  .track-card-footer {
    font-size: 14px;
    font-weight: 400;
    color: $element-400;
    padding: 0px 20px 30px 20px !important;
    line-height: 18.2px;

    span {
      color: $element-900;
      font-weight: 700;
    }
    .track-card-footer-content {
      min-height: 55px;
    }

    .track-card-footer-graph {
      width: 100px;
      height: 50px;
    }
    @media only screen and (max-width: $breakpoint-mobile) {
      display: flex;
      justify-content: flex-end;
      padding: 0 !important;
    }
  }
}

.danger {
  border-color: $accent-orange;
}

.ideal {
  border-color: $element-900;
}
