@import '~styles/variables';

@mixin rectangle($position, $color: $background-500) {
  position: relative;

  &::before {
    height: 32px;
    width: 20px;
    content: '';
    position: absolute;
    left: $position;
    background-color: $color;
  }
}

@mixin highlight($color: $accent-cyan) {
  .content {
    background-color: $color;
    color: $element-900;
  }
}

@mixin highlightWeek() {
  .content {
    background-color: $background-700;
    color: $element-300;
  }
}

@mixin rectangleWeek() {
  position: relative;

  &::before {
    height: 32px;
    width: 20px;
    content: '';
    position: absolute;
    background-color: $background-700;
  }
}

.datepicker-calendar {
  display: flex;
  justify-content: center;
  background-color: $element-800;
  max-width: 369px;

  .content {
    position: relative;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media only screen and (max-width: $breakpoint-mobile) {
      font-size: 11px;
    }
  }

  .weekdays {
    color: $background-100;
  }

  .separator {
    border-right: 2px solid $element-700;
    height: 230px;
    margin: 5px;
  }

  .cell {
    margin: 0 2px;

    @media only screen and (max-width: $breakpoint-mobile) {
      margin: 0 1px;
    }
  }

  .row {
    display: flex;
  }

  .header {
    margin-bottom: 8px;
    display: flex;
    color: $element-500;
  }

  .day-cell {
    color: $element-500;
    cursor: pointer;

    &.this-month {
      color: $background-100;
    }

    &:hover:not(.between):not(.start):not(.end) .content {
      transition: background-color 0.2s ease-out, color 0.2s ease-out;
      background-color: $element-700;
    }

    &.today {
      color: $accent-cyan !important;
    }

    &.end {
      transition: background-color 0.2s ease-out, color 0.2s ease-out;
      @include highlight();
      &.complete {
        @include rectangle(-6px);
      }
    }

    &.start {
      transition: background-color 0.2s ease-out, color 0.2s ease-out;
      @include highlight();
      &.complete {
        @include rectangle(18px);
      }
    }

    &.between:not(.first):not(.last) {
      background-color: $background-500;
      color: $element-900;
      margin: 0;
      padding: 0px 5px;
    }

    &.first {
      @include highlight($background-500);
      @include rectangle(18px);
    }

    &.last {
      @include highlight($background-500);
      @include rectangle(-6px);
    }
  }
}

.week-mode {
  .content {
    cursor: pointer;
  }

  .day-cell.between:not(.first):not(.last) {
    background-color: $background-700;
    color: $element-300;
    margin: 0;
    padding: 0px 2px;
    @media only screen and (max-width: $breakpoint-mobile) {
      padding: 0px 1px;
    }
  }

  .day-cell.first {
    @include highlightWeek();
    @include rectangleWeek();
  }
  .day-cell.last {
    @include highlightWeek();
    @include rectangleWeek();
  }
  .day-cell.end {
    transition: background-color 0.2s ease-out, color 0.2s ease-out;
    @include highlightWeek();
    &.complete {
      @include rectangleWeek();
    }
  }
  .day-cell.start {
    transition: background-color 0.2s ease-out, color 0.2s ease-out;
    @include highlightWeek();
    &.complete {
      @include rectangleWeek();
    }
  }

  .selected-week {
    background-color: $accent-cyan;
    color: $background-700;
    position: relative;
    transform-style: preserve-3d;
    &:after {
      transform: translateZ(-1px);
      content: '';
      position: absolute;
      top: 0;
      right: -5px;
      width: 20px;
      height: 32px;
      background-color: $background-700;
      z-index: 0;
    }
  }
  .start {
    transform-style: preserve-3d;
    &:after {
      transform: translateZ(-1px);
      content: '';
      position: absolute;
      top: 0;
      left: -8px;
      width: 20px;
      height: 32px;
      background-color: $background-700;
      z-index: 0;
      @media only screen and (max-width: $breakpoint-mobile) {
        left: -7px;
      }
    }
  }
}
