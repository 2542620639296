@import '~styles/variables';

.toggle {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  .slider {
    position: absolute;
    cursor: pointer;
    background-color: $background-500;
    border-radius: 20px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: 0.4s;
  }
  &.active {
    .slider {
      background-color: $accent-cyan;
    }
    .slider::before {
      transform: translateX(18px);
    }
  }

  .slider::before {
    position: absolute;
    content: '';
    height: 12px;
    width: 12px;
    left: 6px;
    bottom: 4px;
    background-color: white;
    border-radius: 50%;
    transition: 0.4s;
  }

  &.toggle-disabled {
    pointer-events: none;
    .slider {
      background-color: $background-500;
      &::before {
        background-color: $background-600;
        border: 1px solid $element-400;
      }
    }
  }

  input {
    display: none;
  }

  input[type='checkbox']:checked + .slider::before {
    transform: translateX(15px);
    left: 5px;
  }

  input[type='checkbox']:checked + .slider {
    background-color: $accent-cyan;
  }
}
