@import '~styles/variables';

.container {
  display: flex;
  list-style-type: none;
  align-items: center;

  .item {
    padding: 0 12px;
    height: 32px;
    text-align: center;
    margin: auto 4px;
    color: $background-100;
    display: flex;
    align-items: center;
    border-radius: 3.5px;
    line-height: 1.43;
    font-size: 13px;
    min-width: 32px;

    &:hover {
      background-color: $background-700;
      cursor: pointer;
    }

    &.selected {
      background-color: $background-700;
    }
  }

  .icon-container {
    padding-top: 5.5px;
    height: 32px;
    min-width: 32px;
    margin: auto 4px;
  }

  .icon-left {
    padding-left: 10px;
  }

  .disabled {
    pointer-events: none;
  }

  .icon-disabled {
    fill: $element-400;
  }
}
