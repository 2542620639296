@import '~styles/variables';

.notification-icon {
  position: relative;
  .read-circle {
    border-radius: 50%;
    height: 8px;
    width: 8px;
    background: $accent-orange;
    position: absolute;
    top: 0px;
    left: 0px;
  }
}

// STYLE VARIANT -
.notification-icon.iconBig {
  height: 44px;
  .notif_item_icon {
    width: 50px;
    height: 44px;
    margin-right: 10px;
  }

  .notification-icon-type-variation {
    top: 5px;
    left: 33px;
    position: absolute;

    svg {
      width: 15px;
      height: 15px;
    }
  }
}

.notification-icon.bgDark {
  .notif_item_icon {
    fill: #fff;
    &.disorder {
      stroke: #fff;
    }
  }
  .notification-icon-type-variation {
    top: 5px;
    left: 33px;
    position: absolute;

    svg {
      width: 15px;
      height: 15px;
      position: absolute;
      fill: #373c4f;
      background-color: #fff;
      border-radius: 50%;
    }
  }
}
.notification-icon.iconTooltip {
  .notif_item_icon {
    width: 35px;
    height: 35px;
    margin-right: 10px;
  }
  .notification-icon-type-variation {
    top: 2px;
    left: 22px;
    position: absolute;
  }
}
// STYLE VARIANT - Light Bg
.notification-icon.bgLight {
  .notif_item_icon {
    fill: #373c4f;
    &.disorder {
      stroke: #373c4f;
    }
  }

  .notification-icon-type-variation {
    svg {
      width: 15px;
      height: 15px;
      position: absolute;
      fill: #fff;
      background-color: #373c4f;
      border-radius: 50%;
    }
  }
}
