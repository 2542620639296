@import '~styles/variables';

.contentBackground {
  fill: $element-200;
  margin-right: 20px;
}

.contentBorder {
  stroke: #fff;
}

.tooltipContent {
  fill: $element-200;
}
