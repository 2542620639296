@import '~styles/variables';

.container {
  width: 100%;

  .title {
    font-weight: 700;
    font-size: 36px;
    margin-left: 20px;
    margin-top: 20px;
  }

  .content {
    width: 100%;
    outline: 1px solid gold;
    display: flex;
    gap: 30px;

    @media only screen and (width <= $breakpoint-tablet) {
      flex-direction: column;
    }
  }
}
