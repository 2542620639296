@import '~styles/variables';
@import '~styles/mixins';

.page-container {
  @include page-container;
  .page-header {
    @include page-header;
    .page-header-title {
      @include page-title;
    }
    .page-header-filters {
      @include page-top-filters;
      .cropseasons {
        @include page-top-filters-cropseasons;
      }
    }
  }
  .page-header-subtitle {
    margin-left: 70px;
    color: #fff;
    font-size: 18px;
  }
}

.header-filters {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  .filter {
    display: flex;
    gap: 10px;
  }
}

.yield-graph {
  height: 420px;
  background-color: $background-500;
  border-radius: 8px;
  margin: 20px;
  .chips-row {
    padding: 20px 40px 0 40px;
    gap: 10px;
    display: flex;
    .chip + .chip {
      margin-left: 20px;
    }
  }
}
.yield-graph-container {
  height: 300px;
}

.yield-data-container {
  margin: 50px 40px 20px 40px;

  .input-containers {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
  }
}
.yield-data {
  display: flex;
}

.yield-data-container-actions {
  display: flex;
  margin-bottom: 20px;
  button + button {
    margin-left: 20px;
  }

  .disabled-calculate-btn {
    pointer-events: none;
    background: $element-200;
    border-color: $element-200;
  }
}
.yield-data-title {
  font-size: 14px;
  margin-bottom: 20px;
  margin-left: 20px;
  display: block;
}

.yield-data-row {
  margin-right: 20px;
  padding: 10px 20px;

  .number-input-label {
    min-height: 17px;
    display: inline-block;
    font-size: 12px;
    color: #8293a6;
  }
  .weekNumber {
    background-color: $background-500;
    border: 1px solid #e7f0f3;
    border-radius: 4px;
    padding: 5px 0;
    text-align: center;
    margin-bottom: 10px;
    cursor: pointer;
    width: 118px;
    min-height: 39px;
  }
}

.yield-prediction-row {
  .weekNumber {
    background-color: #a4fa60;
    color: black;
    margin-bottom: 37px;
    width: 118px;
  }
}
