@import '~styles/spectre/variables';
@import '~styles/variables';

.accordion {
  &.hidden {
    display: none;
  }

  .accordion-header {
    display: flex;
    justify-content: space-between;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 700;
    color: $element-200;
    padding: 5px 20px;

    .icon-arrow {
      fill: $element-200;
      transition: transform 0.2s;
      transform: rotate(0deg);
    }
  }

  .accordion-body {
    padding: 0;
    width: 100%;
    max-height: 0px;
    transition: max-height 0.4s ease-in-out;
    font-size: 12px;
    color: #132a37;

    .element-content {
      display: flex;
      font-size: 14px;
      .element-text {
        max-width: 160px;
        padding-left: 10px;
        padding-right: 5px;
      }
    }

    .element {
      &.hidden {
        display: none;
      }
    }

    .element-disabled {
      .element-content {
        pointer-events: none;
        color: $element-400;
      }
    }
  }

  input:checked ~,
  &[open] {
    .accordion-header > {
      .icon-arrow {
        transition: transform 0.2s;
        transform: rotate(180deg);
      }
    }

    .accordion-body {
      max-height: 1000px;
    }
  }
  .accordion-row {
    padding: 5px 20px;
  }
}

.accordion-dark {
  .accordion-body {
    background-color: $background-500;
  }
}

.accordion {
  &.accordion-light {
    .accordion-body {
      background-color: #fff;
      margin-bottom: 0;
    }
    .accordion-header {
      background-color: #394958;
      align-items: center;
      height: 40px;
      display: flex;
    }
    .row-even {
      background-color: #fff;
    }
    .row-odd {
      background-color: #f5f5f5;
    }
  }

  &.accordion-dark {
    .accordion-body {
      font-size: 0.88rem;
      font-style: normal;
      font-weight: 400;
      color: #fff;
    }
    .accordion-row {
      padding: 0 20px;
      .element {
        display: flex;
        vertical-align: middle;
        justify-content: space-between;
        align-items: center;
      }
    }
    .accordion-header {
      display: flex;
      padding: 5px 20px;
    }
    .accordion-row:first-child {
      padding-top: 5px;
    }
    .accordion-row:last-child {
      padding-bottom: 5px;
    }
  }
}
