.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .content {
    width: 50%;
    min-width: 600px;
    overflow-y: auto;
    &.contentFull {
      height: 100%;
      min-height: 100%;
    }
  }
}
