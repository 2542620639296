@import '~styles/variables';

.notification-row {
  font-size: 12px;
  line-height: 14px;
  padding: 15px;
  border-bottom: 1px solid #d2d5db;
  &:nth-last-of-type(1) {
    border-bottom: none;
  }
  position: relative;
}
.notification-row-extra-info {
  display: flex;
  color: $element-40;
  border-bottom: 1px solid $element-40;
  padding: 10px;
  margin-bottom: 10px;
  white-space: pre-wrap;
}

.notification-row-info {
  display: flex;
  color: $element-40;
}
.notification-row-timestamp {
  color: $element-40;
}

.notification-row-description {
  width: 100%;
}

.notification-row-footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  color: $element-400;
  margin-top: 10px;
  @media only screen and (max-width: $breakpoint-tablet) {
    flex-direction: column;
    align-items: flex-start;
    .notification-row-timestamp {
      text-align: left;
    }
  }

  .footer-info {
    display: flex;
    align-items: center;
    vertical-align: middle;
    margin-right: 10px;
  }
}
.notification-info-icon {
  margin-right: 10px;
  fill: $element-300;
}
.notification-footer-icon {
  margin-right: 5px;
  fill: $element-300;
}
.notification-footter-left {
  display: flex;
  max-width: 250px;
  flex-wrap: wrap;
  span {
    white-space: nowrap;
  }
}
.notification-row-timestamp {
  min-width: 150px;
  text-align: right;
}

.notification-row-description,
.footer-info {
  color: $element-300;
}

// STYLE VARIANT - notification-style-type-light
.notification-style-type-light {
  .footer-info {
    color: $element-400;
  }
}

// STYLE VARIANT - notification-style-type-dark
.notification-style-type-dark {
  &.notification-row {
    background-color: $background-200;
    border-bottom: 0;
    transition: background-color 0.3s ease;
    border-radius: 4px;
    margin-bottom: 5px;
    cursor: pointer;
    &:hover {
      background-color: $background-500;
    }

    &.notification-selected {
      background-color: $background-500;
      @media only screen and (max-width: $breakpoint-laptop) {
        background-color: $background-200;
      }
      .notification-row-action {
        opacity: 1;
      }
    }
  }

  .notification-row-info svg {
    fill: #fff;
  }
  .notification-row-description {
    color: #fff;
  }

  .footer-info {
    color: #e7f0f3;
    svg {
      fill: $element-400;
    }
  }
  .notification-row-timestamp {
    color: $element-400;
    font-size: 14px;
    white-space: nowrap;
  }
}

// STYLE VARIANT - notification-type-tooltip
.notification-type-tooltip,
.notification-type-navbar {
  max-width: 450px;
}

.notification-row-description-text {
  font-weight: bold;
}

// STYLE VARIANT - notification-type-full_page
.notification-type-full_page {
  .notification-footter-left {
    max-width: 80%;
  }
  .notification-row-description {
    font-size: 16px;
    line-height: 18px;
    .notification-row-description-text {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
  .footer-info {
    font-size: 14px;
  }
}

// STYLE VARIANT - notification-hasAction
.notification-hasAction {
  &.notification-row {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
  .notification-row-action {
    opacity: 0;
    transition: opacity 0.3s ease;
    @media only screen and (max-width: $breakpoint-laptop) {
      rotate: 90deg;
      transition: rotate 0.5s;
      opacity: 1;
    }
  }
  &:hover {
    .notification-row-action {
      opacity: 1;
    }
  }
  .notification-row-action svg {
    fill: $element-400;
  }
  .notification-row-info {
    width: 100%;
    margin-right: 20px;
  }
}

.notification-selected {
  background-color: $background-500;
  .notification-row-action {
    opacity: 1;
    @media only screen and (max-width: $breakpoint-laptop) {
      rotate: -90deg;
    }
  }
}
