@import '~styles/variables';
@import '~styles/mixins';

.page-container {
  @include page-container;
  .page-header {
    @include page-header;
    .page-header-title {
      @include page-title;
    }
    .page-header-filters {
      @include page-top-filters;
      .cropseasons {
        @include page-top-filters-cropseasons;
      }
    }
  }
  .page-header-subtitle {
    margin-left: 70px;
    color: #fff;
    font-size: 18px;
  }
}
.import-data-upload-content {
  display: flex;
  justify-content: center;
  align-items: center;
}
.import-data-container {
  display: flex;
}

.input-file-upload-container {
  border: 1px solid #e7f0f3;
  background-color: #394958;
  color: #e7f0f3;
  font-size: 12px;
  border-radius: 4px;
  min-width: 450px;
  margin-bottom: 20px;
  input[type='file'] {
    border-radius: 4px;
    width: 100%;
  }
}
