@import '~styles/variables';

.graph {
  width: 100%;
  height: 100%;
  position: relative;

  .legend-container {
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .multiline-chart-svg.max-graph {
    height: 400px;
    min-height: 400px;
  }

  .title-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 50px;
    margin-top: 20px;
    margin-bottom: 10px;

    .title {
      text-align: center;
      font-weight: 700;
      font-size: 24px;
      margin-bottom: 10px;
    }

    .unit {
      font-size: 12px;
    }
  }

  .lines-pattern-legend {
    margin-bottom: 10px;
  }

  .blur-message-container {
    height: 71px;
    width: 284px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    background-color: #576776;
    border-radius: 4px;
    color: $element-900;
    display: flex;
    align-items: center;
    justify-content: center;

    .blur-message {
      font-size: 14px;
      margin: 30px;
    }
  }

  .svg {
    flex: 1;
    position: relative;

    &.blur {
      pointer-events: none;
      filter: blur(3px);
    }

    &.padding {
      padding-left: 30px;
      @media only screen and (max-width: $breakpoint-laptop) {
        padding-left: 0;
      }
    }
  }
}

.guardrails-graph {
  .title {
    margin-bottom: 2%;
  }
}
.general-graph {
  position: relative;
}

rect:focus {
  outline: none;
}
