@import '~styles/variables';

.container {
  width: 60%;
  height: 200px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: left;

  .title {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 30px;
  }

  .content {
    font-size: 12px;
  }

  .large-button {
    width: 140px;
    height: 40px;
    margin-top: 30px;
    margin-right: auto;

    @media only screen and (width <= $breakpoint-mobile) {
      margin-right: unset;
    }
  }

  @media only screen and (width <= $breakpoint-tablet) {
    padding: 0 20px;
  }
  @media only screen and (width <= $breakpoint-mobile) {
    width: 100%;
    padding: 0 20px;
  }
}
