@import '~styles/spectre/variables';
@import '~styles/variables';

.static-input {
  background-color: $background-500;
  border-radius: 4px;
  padding: 8px 10px;
  width: 90px;
}

.header-text {
  margin-bottom: 2px;
  color: #8293a6;
  font-size: 11px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 90px;
}

.icon-container {
  float: right;
  svg {
    fill: #fff;
  }
}
.static-input-container {
  .static-input-left {
    text-align: left;
  }
  .static-input-center {
    text-align: center;
  }
  .static-input-right {
    text-align: right;
  }
}

.static-input-container-medium {
  .static-input,
  .header-text {
    width: 120px;
  }
}
.static-input-container-small {
  .static-input,
  .header-text {
    width: 90px;
  }
}
