@import '~styles/variables';

.env-errors-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $background-100;

  ul {
    flex: 1.5;
    li {
      color: $element-700;
    }
  }

  h1 {
    flex: 0.1;
    margin-top: 20px;
    justify-self: flex-start;
    color: $accent-orange;
    text-align: center;
  }

  h4 {
    color: #fff;
    margin: 0 5%;
    max-width: 900px;
    line-height: 1.4;
  }
}
