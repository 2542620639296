@import '~styles/variables';

.fixed-footer-legend {
  min-height: 50px;
  background-color: #233545;
  border-top: 1px solid #394958;
  position: absolute;
  bottom: 0;
  margin-left: -5px;
  left: 0;
  right: 0;
  padding: 10px;
  transition: margin-left 0.3s;
  &.isSidebarOpened {
    margin-left: $sidebarOpenedWidth;
  }
}
.track-header-container {
  position: relative;
  .track-dates-error {
    color: #fb424d;
    margin-top: -30px;
    margin-bottom: 25px;
    text-align: right;
    font-size: 12px;
    max-width: 195px;
    right: 170px;
    position: absolute;
    text-align: left;
  }
}
