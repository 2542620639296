@import '~styles/variables';
.dashboard-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0px 27px;
  transition: margin 0.3s;

  .dashboard-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 40px 0px 25px 0px;

    .cropseason-dropdown-container {
      width: 300px;
      z-index: 1;
      margin-left: auto;
      transform: translateY(18px);
      @media only screen and (width < $breakpoint-laptop) {
        transform: translateY(8px);
      }
    }

    @media only screen and (width < $breakpoint-laptop) {
      margin: 20px 0px 25px 0px;
    }
  }

  .warning-message {
    width: 100%;
    display: flex;
    align-items: center;
    background-color: $accent-cyan;
    color: $background-100;
    border-radius: 6px;
    flex-direction: row;
    font-size: 14px;
    margin-bottom: 50px;
    min-height: 52px;

    span {
      font-weight: 700;
    }

    .icon-container {
      display: flex;
      align-items: center;
    }

    .icon-dark {
      margin: 0px 14px;
    }

    @media only screen and (max-width: $breakpoint-tablet) {
      min-height: 72px;
      flex-direction: row;
      margin-bottom: 30px;

      .message {
        display: flex;
        flex-direction: column;
      }
    }

    @media only screen and (max-width: $breakpoint-mobile) {
      top: 140px;
      height: 98px;
    }
  }

  .blurred-content {
    filter: blur(5px);
    pointer-events: none;
  }

  .cards-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 23px 30px;
    grid-auto-flow: row;
    grid-template-areas:
      'climate water-management fresh-weight-gain'
      'water-efficiency crop-status production';

    .climate {
      grid-area: climate;
    }

    .water-management {
      grid-area: water-management;
    }

    .fresh-weight-gain {
      grid-area: fresh-weight-gain;
    }

    .water-efficiency {
      grid-area: water-efficiency;
    }

    .crop-status {
      grid-area: crop-status;

      .large-graph {
        margin-top: -20px;
        height: 140%;
      }
    }

    .production {
      grid-area: production;
    }

    .dashboard-link {
      color: inherit;
      text-decoration: inherit;
    }

    @media only screen and (width < $breakpoint-cards-2-columns) {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr;

      grid-template-areas:
        'climate water-management '
        'water-efficiency crop-status '
        'fresh-weight-gain production';
    }

    @media only screen and (width < calc($breakpoint-laptop + 59px)) {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr;

      grid-template-areas:
        'climate water-management '
        'water-efficiency crop-status '
        'fresh-weight-gain production';
      gap: 12px;
    }
    @media only screen and (width < $breakpoint-tablet) {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;

      grid-template-areas:
        'climate'
        'water-management'
        'water-efficiency'
        'crop-status'
        'fresh-weight-gain'
        'production';
      gap: 10px;

      .crop-status {
        .large-graph {
          margin-top: 0;
          height: 100%;
        }
      }
    }
  }

  .dashboard-page-title {
    margin: 0;
    margin-top: 12px;
    font-size: 14px;
    color: $element-500;
    display: none;
    @media only screen and (width < $breakpoint-laptop) {
      display: inherit;
    }
  }
}
.sidebar-closed {
  margin: 0px 107px;

  @media only screen and (max-width: $breakpoint-mobile) {
    margin: 0px 16px;
  }
}
.dashboard-container {
  @media only screen and (width < $breakpoint-laptop) and (width > $breakpoint-mobile) {
    margin: 0 20px;
  }
  @media only screen and (width < $breakpoint-mobile) {
    margin: 0 16px;
  }
}
