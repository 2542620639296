@import '~styles/variables';
@import '~styles/spectre/variables';

.sidebar-content {
  margin: 18px 24px 0px 24px;
  display: flex;
  flex-direction: column;

  .info-text {
    height: auto;
  }

  .sidebar-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .sidebar-header-title {
      font-weight: bold;
      font-size: 12px;
    }
    hr .close-directories-button {
      display: flex;
      align-items: center;
    }
    margin-bottom: 30px;
  }
  .sidebar-crop-location {
    margin-top: 0;
  }
  hr {
    margin: 20px 0 20px 0;
    width: 100%;
    border: none;
    border-bottom: 1px solid #394958;
  }
  .sidebar-buttons-container {
    margin-top: 30px;
    display: flex;
    gap: 20px;
    width: 100%;
    position: fixed;
    width: 295px;
    bottom: 70px;
    background-color: $background-200;
    left: 20px;
    align-items: center;
    justify-content: center;
    height: 70px;
    border-top: 1px solid $background-600;
    .sidebar-cancel-button {
      width: 110px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .custom-plan-settings-container {
    display: flex;
    align-items: center;
    gap: 10px;

    .custom-plan-settings-text {
      font-weight: bold;
      font-size: 14px;
    }
    &:hover {
      .custom-plan-settings-text {
        color: $accent-blue;
      }
      .custom-plan-settings-icon {
        fill: $accent-blue;
      }
    }
  }

  .crop-season {
    margin-top: 20px;
  }

  margin-bottom: 20px;
  padding-bottom: 140px;
}

.create-new-container {
  .directories-accordion {
    width: 180px;
    height: 36px;
    background: $accent-cyan;
    border-radius: 4px;
    text-align: center;
    font-weight: 700;
    font-size: 14px;
    margin: 40px 10px;
    position: relative;

    input:checked ~,
    &[open] {
      & .directories-accordion-body {
        position: absolute;
        width: 180px;
        top: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-radius: 6px;
        box-shadow: 0px 3px 10px rgba(26, 26, 26, 0.15);
        background: $element-900;
        color: $background-100;
        max-height: 50rem;
        margin-top: 10px;
        padding: 6px 0px;
      }
    }

    .directories-accordion-item {
      width: 180px;
      font-weight: 400;
      font-size: 14px;
      padding-left: 20px;
      height: 40px;
      display: flex;
      align-items: center;

      &:hover {
        background: $background-800;
      }
    }
  }
}

.crop-season {
  margin-top: 45px;
}
