.track-header-container {
  position: relative;
  .track-dates-error {
    color: #fb424d;
    margin-top: -30px;
    margin-bottom: 25px;
    text-align: right;
    font-size: 12px;
    max-width: 195px;
    right: 170px;
    position: absolute;
    text-align: left;
  }
}
