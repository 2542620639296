@import '~styles/variables';

.main-container {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;

  .amount-al-toggle {
    display: flex;

    align-items: center;
    width: 100%;
  }
  .al-toggle-text {
    font-size: 14px;
    margin-bottom: 10px;
    width: 100%;
  }

  .artificial-lights-container {
    display: flex;

    align-items: center;
    flex-direction: column;

    .amount-row {
      display: flex;
      align-items: center;
      gap: 20px;
      .amount-title {
        width: 200px;
        font-size: 14px;
      }
      .amount {
        width: 120px;
      }
    }
    margin-bottom: 10px;
  }

  .blur-message-container {
    height: 71px;
    width: 350px;
    position: absolute;

    background-color: #576776;
    border-radius: 4px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    color: $element-900;
    display: flex;
    align-items: center;
    justify-content: center;

    .blur-message {
      width: 300px;
      font-size: 14px;
      margin: 30px;
    }
  }
  .blur-content {
    filter: blur(2px);
    pointer-events: none;
  }
}
