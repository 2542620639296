@import '~styles/variables';
@import '~styles/icons';

.notification-tooltip-end {
  position: absolute;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 70%,
    rgba(255, 255, 255, 0.8) 100%
  );
  width: 100%;
  margin-top: -50px;
  border-radius: 0 0 4px 4px;
  display: flex;
  align-items: center;
  vertical-align: middle;
  min-height: 50px;
  justify-content: center;
  color: $element-40;
  font-size: 12px;
  font-weight: 700;
}
