@import '~styles/variables';
.generic-item {
  font-weight: 400;
  font-size: 14px;
  padding-left: 20px;
  height: 40px;
  display: flex;
  align-items: center;

  &:hover {
    background: $background-700;
  }
}
