@import '~styles/variables';

.dropdown {
  position: relative;
  width: 100%;

  .required-text {
    color: $accent-red;
    font-size: 12px;
  }
  .menu .item-container .item.margin-item {
    margin-left: 8px;
  }

  @media only screen and (width < $breakpoint-mobile) {
    width: 100%;
  }
}

.hover {
  background-color: $background-700;
}

.arrow-icon {
  width: 30px;
  height: 30px;
  margin-right: 15px;
  &.custom-range-middle {
    transform: rotate(90deg);
  }
}

.custom-range-container {
  cursor: default;
  width: 409px;
  height: 526px;
  border-radius: 4px;
  background-color: $element-900;
  z-index: 1;
  @media only screen and (width < $breakpoint-mobile) {
    height: 550px;
  }

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .datepickers-container {
    margin-top: 20px;
    width: 369px;
    display: flex;
    justify-content: center;
    align-self: center;
    gap: 5px;
    margin-bottom: 30px;
    .from-date-picker {
      flex: 1;
      max-width: 183px;
    }
    .to-date-picker {
      max-width: 183px;
      flex: 1;
    }
  }
  .buttons-container {
    display: flex;
    justify-content: flex-end;
    gap: 5px;
    margin-right: 20px;
    margin-bottom: 40px;
    .cancel-button {
      color: $background-500;
      border: 1px solid $background-500;
      font-size: 14px;
      width: 100px;
      height: 40px;
    }
    .apply-button {
      color: $element-900;
      font-size: 14px;
      width: 100px;
      height: 40px;
      border: 1px solid $accent-cyan;
      background-color: $accent-cyan;
      &:hover {
        background-color: $accent-blue;
        border: 1px solid $accent-blue;
      }
    }
  }
  @media only screen and (width <= $breakpoint-mobile) {
    width: calc(100vw - 50px);
    .datepickers-container {
      width: 100%;
      flex-direction: column;
      .from-date-picker {
        margin: 0 20px;
        min-width: calc(100% - 40px);
      }
      .to-date-picker {
        width: 100%;
        min-width: calc(100% - 40px);
        margin: 0 20px;
      }
    }

    .buttons-container {
      margin-right: 0px;
      margin-bottom: 0px;
    }
  }
}
