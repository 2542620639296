@import '~styles/spectre/variables';
@import '~styles/variables';

.generic-button-hollow {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border: 1px solid #fff;
  color: #fff;
  height: 30px;
  background-color: transparent;
  &.button-disabled {
    opacity: 0.4;
    pointer-events: none;
    .plus-icon {
      opacity: 0.5;
    }
  }

  &:hover {
    color: $accent-blue;
    border: 1px solid $accent-blue;

    .plus-icon {
      fill: $accent-blue;
    }
  }

  .plus-icon {
    min-width: 20px;
    min-height: 20px;
  }
}

.generic-button-base {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  display: flex;
  align-items: center;
  border-radius: 4px;
  font-weight: 700;
  font-size: 12px;
  line-height: 130%;
  gap: 10px;
  cursor: pointer;
}

.generic-button-medium {
  padding: 10px 20px;
  min-width: 100px;
}

.generic-button-primary {
  background: $accent-cyan;
  border: 1px solid $accent-cyan;
  color: #ffffff;
  &:hover {
    background: #00a0da;
    border: 1px solid #00a0da;
    color: #ffffff;
  }
}

.generic-button-secondary {
  background: #fff;
  border: 1px solid #576776;
  color: #576776;
  &:hover {
    background-color: #f0f0f0;
  }
}

.generic-button-base + .generic-button-base {
  margin-left: 10px;
}
