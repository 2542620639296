@import '~styles/variables';
@import '~styles/icons';
@import '~styles/mixins';

.date-picker {
  height: min-content;

  + .error-text {
    @include error-text;
  }

  .datepicker-menu {
    display: none;
    position: absolute;
    z-index: $zindex-4;
    @media only screen and (max-width: $breakpoint-mobile) {
      width: 100%;
    }
  }

  .dropdown-datepicker-middle {
    .datepicker-menu {
      left: auto;
      top: auto;
      right: -125px;
    }
  }
  .dropdown-datepicker-left {
    .datepicker-menu {
      left: 0;
    }
    &.custom-range {
      .datepicker-menu {
        top: 70px;

        @media only screen and (max-width: $breakpoint-mobile) {
          top: 128px;
        }
      }
    }
  }
  .dropdown-datepicker-top {
    .datepicker-menu {
      bottom: -10px;
    }
  }
  .dropdown-datepicker-bottom {
    .datepicker-menu {
      top: 70px;
    }
  }
  .dropdown-datepicker-right {
    .datepicker-menu {
      left: auto;
      right: 0px;

      @media only screen and (max-width: $breakpoint-mobile) {
        top: 57px;
      }
    }
    &.custom-range {
      .datepicker-menu {
        top: 60px;
        @media only screen and (max-width: $breakpoint-mobile) {
          top: 57px;
        }
      }
    }
  }

  label {
    display: inline-block;
    font-size: 12px;
    color: $element-400;
    &.required {
      &::after {
        content: '*';
        color: #fb424d;
      }
    }
  }
  .dropdown.show-datepicker {
    background-color: $background-100;
  }
  .show-datepicker {
    .datepicker-menu {
      display: block;
      .date {
        margin-top: 1px;
      }
    }
  }

  .dropdown {
    font-size: 14px;
    width: 100%;
    background-color: $background-500;
    border: 1px solid $background-700;
    border-radius: 4px;

    .dropdown-toggle {
      margin: 1px;
      padding: 6px 10px;
      display: flex;
      justify-content: space-between;
      cursor: pointer;

      .text {
        color: $element-900;
        width: 8.5rem;
      }
    }

    &.disabled {
      border-color: $element-100;
      color: $element-100;
      pointer-events: none;
      .text {
        color: $element-100;
      }
      .calendar-icon {
        fill: $element-100;
      }
    }

    &.custom-range {
      background-color: $element-900;
      border: 1px solid $element-300;
      .text {
        color: $element-300;
      }
      .calendar-icon {
        fill: $element-300;
      }
    }
    &.focus {
      background-color: $background-100;
    }
    &.custom-range {
      background-color: $element-900;
      border: 1px solid $element-300;
      .text {
        color: $element-300;
      }
      .calendar-icon {
        fill: $element-300;
      }
      &.focus {
        background-color: $background-700;
      }
    }

    .date-picker-dropdown {
      border-radius: 4px;
      border-top-left-radius: 24px;
      border-top-right-radius: 24px;
      width: 369px;
      height: 300px;
      background-color: $element-800;
      font-weight: 700;
      margin-top: 10px;
    }

    .menu {
      padding: 0;
      overflow: hidden;
    }
  }

  @media only screen and (max-width: $breakpoint-mobile) {
    width: 100%;
    .dropdown {
      .date-picker-dropdown {
        width: 100%;
      }
    }
  }
}

.label-left {
  display: flex;
  align-items: center;

  label {
    margin-right: 10px;
  }
}

.label-top {
  display: flex;
  flex-direction: column;
  label {
    margin-bottom: 5px;
  }
}

.week-mode {
  max-height: 40px;
  .dropdown {
    width: 120px;
  }
  .calendar-icon {
    min-width: 24px;
  }
  label {
    margin-bottom: 2px;
  }
}
