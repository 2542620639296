@import '~styles/variables';

.container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .content {
    width: 90%;
    height: 450px;
    display: flex;
    flex-direction: column;
    justify-content: top;
    align-items: center;
    .details {
      display: flex;
      justify-content: space-around;
      width: 80%;
      display: flex;
      height: 25%;

      .column {
        width: 25%;
      }

      .details-title {
        font-size: 16px;
        color: $element-200;

        .details-value {
          color: $element-900;
          margin-left: 10px;
        }
      }
    }

    .graphs-container {
      width: 80%;
      margin-top: 10px;
    }

    .preview {
      .preview-text {
        margin-left: 10px;
      }
      margin-top: 45px;
      display: flex;
      align-items: center;
    }
  }
}
