@import '~styles/variables';
.preview-graph-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 50px 0px 30px 0px;
  font-size: 18px;
  gap: 80px;

  @media only screen and (max-width: $breakpoint-tablet) {
    margin-top: 27px;
  }

  @media only screen and (max-width: $breakpoint-mobile) {
    margin: 20px 0;
  }
}

.plan-graphs-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
  justify-content: space-between;
}

.preview-graph-container {
  width: 24%;
  height: 280px;
  border-radius: 8px;
  background-color: $background-200;

  @media only screen and (max-width: $breakpoint-laptop) {
    width: 49%;
  }

  @media only screen and (max-width: '561px') {
    width: 100%;
    height: 300px;
  }
  .multi-line {
    height: 50%;
  }
}

.preview-input-graph-container {
  background-color: $background-500;
}
