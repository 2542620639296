@import '~styles/variables';

.lines-pattern-legend {
  display: flex;
  justify-content: center;
  &.lines-pattern-legend-disabled {
    cursor: pointer;
    .lines-pattern-legend-text {
      color: $element-400;
    }
    svg {
      stroke: $element-400;
    }
  }
}
