@import '~styles/variables';

.compare-strategy-graph-page {
  background-color: #fff;
  width: 100%;
  overflow: auto;
}
.compare-strategy-graph-body {
  padding: 40px 27px;
}

.compare-strategy-graph-title {
  display: flex;
  align-items: center;
  h2 {
    color: $element-40;
    font-weight: 700;
    font-size: 36px;
    line-height: 125%;
    letter-spacing: 0.5px;
  }
  svg {
    fill: $element-40;
    width: auto;
    height: 35px;
    margin-right: 15px;
  }
}
