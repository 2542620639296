@import '~styles/variables';
.edit-plans-expected-yield {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 18px;
  .yield-text {
    font-size: 22px;
    margin-right: 10px;
    display: inline-block;
    &.emphasised {
      font-weight: bold;
    }
  }
}
.graph-content-header {
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: $breakpoint-mobile) {
    width: 100%;
    outline: 1px solid gold;
  }
}
.plan-graphs-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
  justify-content: space-between;
}

.edit-plan-graph-container {
  width: 24%;
  border-radius: 8px;
  background-color: $background-200;
  .multiline-chart-svg {
    max-height: 150px;
  }

  @media only screen and (max-width: $breakpoint-laptop) {
    width: 49%;
  }

  @media only screen and (max-width: '561px') {
    width: 100%;
    height: 300px;
  }
  .multi-line {
    height: 50%;
    margin: 55px 0px;
    margin-bottom: 20px;
  }
}

.edit-plan-input-graph-container {
  background-color: $background-500;
  .multiline-chart-svg {
    max-height: 150px;
  }
}
