@import '~styles/variables';
@import '~styles/text-overflow';

.card {
  min-height: 62px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 264px;
  background: $background-200;
  box-shadow: 0px 3px 35px rgba(2, 28, 43, 0.45);
  border-radius: 8px;
  color: $element-900;
  .content {
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    .icon {
      margin-left: 10px;
      display: flex;
      align-items: center;
    }
    .title-container {
      display: flex;
      align-items: center;
      flex: 1;
      .title {
        margin-left: 10px;
        font-size: 14px;
        font-weight: 700;
        height: 100%;
      }
      .description {
        margin-left: auto;
        font-size: 12px;
        color: $element-400;
        min-width: 140px;
        flex: 1;
      }
      @media only screen and (max-width: $breakpoint-mobile) {
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        .description {
          margin-left: 10px;
          margin-right: auto;
          min-width: initial;
          font-size: 12px;
          color: $element-400;
        }
      }
    }
    .input-container {
      display: flex;
      .input {
        width: 100%;
        max-width: 160px;
        margin-left: 5px;
        margin-right: 10px;
        background-color: transparent;
        color: $element-900;
        outline: none;
        border: none;
        border-bottom: 1px solid $element-900;
      }
      .not-valid {
        border-bottom: 1px solid $accent-red;
      }
      .confirmations-container {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        margin-right: 5px;
        .actions-icon {
          &:hover {
            fill: $accent-blue;
          }
        }
      }
    }
  }
  .actions {
    width: 50px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .actions-icon {
      margin-top: 2px;
    }
  }

  .accordion-generic-card {
    position: relative;
    width: 30px;
    height: 30px;
    text-align: center;
    input:checked ~,
    &[open] {
      & .accordion-generic-card-body {
        z-index: 1;
        width: 119px;
        position: absolute;
        right: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-radius: 6px;
        box-shadow: 0px 3px 10px rgba(26, 26, 26, 0.15);
        background: $element-900;
        color: $background-100;
        padding: 10px 2px;
      }
    }
  }
  @media only screen and (min-width: $breakpoint-tablet) and (max-width: $breakpoint-laptop) {
    flex: 1;
    min-width: 358px;
    max-width: calc((100vw - 52px) / 2);
  }
  @media only screen and (max-width: $breakpoint-tablet) {
    width: 100%;
  }
}
.light-mode {
  width: 100%;
  background: $element-900;
  color: $background-100;
  .content {
    .title-container .title {
      width: fit-content;
      width: 110px;
      font-weight: 400;
      @include text-overflow;
    }

    .input-container {
      .actions-icon {
        fill: $background-400;
      }
      .input {
        color: #000;
      }
    }
  }

  @media only screen and (max-width: $breakpoint-laptop) {
    min-width: 100%;
  }
}
