@import '~styles/variables';

.graph-container {
  .actual-quadrant-graph-container {
    width: 49.5%;
    padding: 10px;
    border-radius: 8px;
    background: $background-200;
    box-shadow: 0px 3px 35px rgba(2, 28, 43, 0.27);
    margin: 1% 0px;
    svg {
      @media only screen and (width > $breakpoint-laptop-large) and (width < $breakpoint-svg-large-screen) {
        padding: 0 1%;
      }
      @media only screen and (width > $breakpoint-svg-large-screen) {
        padding: 0 5%;
      }
    }

    .graph-title {
      display: flex;
      justify-content: flex-start;
      margin: 20px 0px;
      font-weight: 700;
      font-size: 24px;
      padding-left: 145px;
      &.plant-balance-graph {
        margin-bottom: 0;
      }
    }

    @media only screen and (max-width: $breakpoint-mobile) {
      width: 100%;
      height: 300px;
    }
  }
}

.track-header-container {
  position: relative;
  .track-dates-error {
    color: #fb424d;
    margin-top: -30px;
    margin-bottom: 25px;
    text-align: right;
    font-size: 12px;
    max-width: 195px;
    right: 170px;
    position: absolute;
    text-align: left;
  }
}
