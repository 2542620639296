@import 'themes';

@mixin themify($property, $key, $themes: $themes) {
  @each $theme, $colors in $themes {
    &.#{$theme}-theme,
    .#{$theme}-theme & {
      #{$property}: map-get($colors, $key);
    }
  }
}
