@import '~styles/variables';
@import '~styles/spectre/variables';
@import '~styles/themify';
@import '~styles/toasts';

.app-container {
  width: 100vw;
  height: 100vh;
  display: grid;

  @include themify('background', 'background');
  @include themify('color', 'foreground');

  grid-template-columns: auto 1fr 1fr;
  grid-template-rows: auto 1fr 1fr;
  gap: 0px 0px;
}

body.isDragging {
  cursor: grabbing;
}

.table-view {
  background: #fff !important;
}

svg:focus {
  outline: none;
}

.header-container {
  grid-area: 1 / 1 / 2 / 4;
  z-index: calc(#{$zindex-5} + 2);
}

.sidebar-container {
  grid-area: 1 / 1 / 4 / 2;
  z-index: calc(#{$zindex-5} + 1);
}

.main-container {
  grid-area: 2 / 2 / 4 / 4;
  overflow: auto;
  display: flex;
  justify-content: center;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none !important;
  }
}

@media only screen and (max-width: $breakpoint-laptop) {
  .app-container {
    display: grid;
    grid-template-columns: 1fr 1fr auto;
    grid-template-rows: auto 1fr 1fr;
    gap: 0px 0px;
  }

  .header-container {
    grid-area: 1 / 1 / 2 / 4;
  }

  .sidebar-container {
    grid-area: 1 / 3 / 4 / 4;
    z-index: calc(#{$zindex-5} + 2);
  }

  .main-container {
    grid-area: 2 / 1 / 4 / 4;
    overflow: auto;
  }
}
