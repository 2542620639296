@import '~styles/variables';

.axis {
  text {
    color: $element-900;
    font-size: 12px;
    @media only screen and (max-width: $breakpoint-laptop) {
      font-size: 10px;
    }
  }
}
