@import '~styles/variables';

.notification-accordion {
  position: relative;
  @media only screen and (max-width: $breakpoint-tablet) {
    position: static;
  }
  .notifications-body {
    display: none;
  }
  input:checked ~,
  &[open] {
    .notifications-body {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      border-radius: 6px;
      box-shadow: 0px 3px 10px rgba(26, 26, 26, 0.15);
      margin-top: 5px;
      background: $element-900;
      color: $background-100;
      padding: 10px 2px;
      max-width: 450px;
      width: 450px;
      @media only screen and (max-width: $breakpoint-tablet) {
        width: calc(100% - 10px);
      }
    }
  }

  .header-notifications {
    .read-circle {
      border-radius: 50%;
      height: 12px;
      width: 12px;
      background: $accent-orange;
      position: absolute;
      right: 8px;
      top: 2px;
    }
  }

  .notifications-body {
    position: absolute;
    right: 7px;
    overflow: hidden;
    width: 0px;
  }

  .title {
    font-size: 14px;
    font-weight: 700;
    color: $background-200;
    margin: 12px 16px;
  }

  .notifications {
    max-height: 340px;
    overflow-y: auto;
  }

  .footer {
    height: 40px;
    font-size: 12px;
    font-weight: 700;
    color: $background-100;
    padding-top: 10px;
    margin: 0px auto;
    div {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      width: fit-content;
    }
  }
}
