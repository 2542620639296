@import '~styles';

.tab-container {
  .tab-header {
    color: $element-400;
    display: inline-flex;
  }

  .tab-link {
    cursor: pointer;
    border-bottom: $border-width-lg solid transparent;
    color: inherit;
    display: block;
    margin: 0 $unit-2;
    padding: $unit-2 10px $unit-2 - $border-width-lg 10px;
    text-decoration: none;
    font-size: 16px;
    &:focus,
    &:hover {
      color: $link-color;
    }
  }
  .tab-item {
    &.active .tab-link {
      border-bottom-color: $primary-color;
      color: $link-color;
    }
  }

  .tab-content {
    width: 100%;
  }

  .profile-settings-view {
    display: flex;
    justify-content: center;
    margin-top: 70px;
  }
}
