@import '~styles/variables';

.variety-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;

  .dropdown-containers {
    display: flex;
    gap: 30px;

    .input {
      width: 240px;
    }
  }

  @media only screen and (max-width: $breakpoint-laptop) {
    flex-direction: column;
    margin-top: 60px;

    .dropdown-containers {
      flex-direction: column;
    }

    .step-title {
      margin-bottom: 100px;
    }
  }

  @media only screen and (max-width: $breakpoint-mobile) {
    flex-direction: column;
    margin-top: 0px;

    .dropdown-containers {
      flex-direction: column;
      .input {
        width: auto;
      }
    }

    .step-title {
      margin-bottom: 0px;
      font-size: 18px;
      align-self: flex-start;
    }
  }
}
