@import '~styles/variables';

.notification-details-container {
  background-color: $background-500;
  padding: 20px;
  margin-left: 5px;
  padding-bottom: 60px;
  @media only screen and (max-width: $breakpoint-laptop) {
    margin-left: 0;
    margin-top: -5px;
    margin-bottom: 5px;
    background-color: $background-200;
    padding-bottom: 30px;
  }
}

.notification-details-header {
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: $breakpoint-laptop) {
    display: none;
  }
}
.notification-details-close {
  cursor: pointer;
}

.notification-details-header-title {
  font-size: 18px;
  font-weight: bold;
  color: #fff;
}
