@import '~styles/variables';

.save-step {
  margin-top: 10px;
  width: 100%;
}
.save-as-new-folder {
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 12px;
  margin-top: 20px;
  cursor: pointer;

  .icon-fill {
    margin: 0 8px 3px 0;
    fill: $element-800;
  }
}

.input-container-save-as {
  width: 100%;
}

.save-as-file-list-container {
  width: 100%;
  .file-list-container {
    width: 100%;
    height: 209px;
  }
}

.save-as-modal-content {
  background-color: $background-200;
}

.modal-component .modal {
  .modal-header {
    .modal-title {
      font-size: 16px;
    }
  }
  .modal-container {
    background-color: $background-200 !important;
  }
}
