@import '~styles/variables';
.legend-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  gap: 0 20px;

  .react-tooltip {
    z-index: 1;
  }
}
