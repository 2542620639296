@import '~styles/variables';

.main-container {
  width: 100%;
  .container {
    height: 90%;
    background: $element-900;
    width: 100%;
    padding: 40px 27px 25px 27px;
    display: flex;
    flex-direction: column;

    .header {
      color: $background-100;
      font-size: 36px;
      font-weight: 700;
      display: flex;
      align-items: center;
      gap: 10px;
      margin-bottom: 30px;
    }

    .plan-details {
      width: 100%;
      height: 120px;
      background: $background-700;
      color: $background-100;
      padding: 25px 12px;
      border-radius: 4px;
      margin-bottom: 18px;

      .plan-details-title {
        font-size: 18px;
        font-weight: 700;
        display: flex;
        gap: 10px;
        margin-bottom: 20px;
      }

      .plan-details-variables {
        display: flex;
        gap: 20px;

        .variable {
          display: flex;
          font-size: 16px;
          gap: 10px;
          .name {
            color: $element-200;
          }

          .value {
            color: $background-500;
          }
        }
      }
    }
    .content {
      display: flex;
      gap: 30px;
      min-height: 0;
      height: 65%;

      .table-container {
        width: 100%;

        .table-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: $background-100;
          font-size: 18px;
          font-weight: 700;
          margin-bottom: 6px;

          .header-pagination {
            display: flex;
            align-items: center;
            .pagination-text {
              min-width: 150px;
              font-size: 12px;
              font-weight: 400;
              color: $element-200;
            }

            .pagination-dropdown {
              z-index: 1;
              width: 150px;
              transform: translateY(6px);
            }
          }
        }

        .table {
          height: 86%;
        }
      }
    }
    .pagination {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .page-footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
    gap: 10px;

    .disabled-save-button {
      background: $background-700;
      color: $element-200;
      border-color: $background-700;
    }

    .disabled-compare-button {
      color: $element-200;
      border-color: $element-200;
    }
  }
}
