@import '~styles/variables';

.date-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  margin-bottom: 40px;

  .dropdown-containers {
    display: flex;
    gap: 30px;

    .datepicker-container {
      width: 240px;
      position: relative;
      .error-text {
        display: block;
        height: 7px;
        color: $accent-red;
        font-size: 11px;
      }
      .preffiled-text {
        display: block;
        height: 7px;
        color: $accent-cyan;
        font-size: 11px;
      }
    }
  }

  .date-step-container {
    &.date-step-vertical {
      flex-direction: column;
      align-items: center;
      border-left: 1px solid $background-500;
      padding-left: 19px;

      .timeline-dot {
        width: 11px;
        height: 11px;
        background-color: $background-500;
        position: absolute;
        left: -25px;
        top: 40px;
        border-radius: 50%;
        &.timeline-ripening {
          top: 60px;
        }
      }

      .timeline-dot.timeline-first::before {
        content: '';
        width: 10px;
        background-color: $background-100;
        position: absolute;
        top: -40px;
        bottom: 11px;
        left: 0;
      }
      .timeline-dot.timeline-last::after {
        content: '';
        width: 10px;
        background-color: $background-100;
        position: absolute;
        top: 11px;
        bottom: -20px;
        left: 1px;
      }

      .date-picker.week-mode {
        .dropdown {
          width: 100%;
        }
      }
    }
  }

  .custom-plan-container {
    margin-top: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7px;
    padding: 0 5px;
    .custom-plan-text {
      font-size: 14px;
      font-weight: bold;
    }
    &.custom-plan-container-disabled {
      &:hover {
        .custom-plan-text {
          pointer-events: none;
          cursor: default;
          text-decoration: none;
          color: #fff;
        }
        .custom-plan-icon {
          fill: #fff;
        }
      }
    }
    &:hover {
      .custom-plan-text {
        color: $accent-blue;
      }
      .custom-plan-icon {
        fill: $accent-blue;
      }
    }
  }

  @media only screen and (max-width: $breakpoint-laptop) {
    flex-direction: column;
    margin-top: 60px;

    .dropdown-containers {
      flex-direction: column;
    }

    .step-title {
      margin-bottom: 100px;
    }
  }

  @media only screen and (max-width: $breakpoint-mobile) {
    flex-direction: column;
    margin-top: 0px;

    .dropdown-containers {
      flex-direction: column;

      .datepicker-container {
        width: auto;
      }
    }

    .step-title {
      margin-bottom: 0px;
      font-size: 18px;
      align-self: flex-start;
    }
  }
}
