@import '~styles/variables';

.track-page-header {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;
  justify-content: space-between;
  .track-page-title {
    font-weight: 700;
    font-size: 36px;
    margin-right: 20px;
  }
  .header-filters-container {
    z-index: 1;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-end;
    gap: 20px;
    .cropseason-dropdown-container {
      width: 300px;
      transform: translateY(16px);
    }
    .cropseason-date-container {
      display: flex;
      min-width: 200px;
    }
  }

  .cropseason-date-container {
    width: 200px;
    display: flex;
  }

  .cropseason-resolution-container {
    width: 150px;
    display: flex;
    transform: translateY(16px);
  }

  @media only screen and (max-width: $breakpoint-laptop) {
    margin-bottom: 20px;
  }

  @media only screen and (max-width: $breakpoint-tablet) and (min-width: $breakpoint-mobile) {
    margin-bottom: 20px;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;

    .track-page-title {
      font-size: 36px;
      margin-bottom: 20px;
    }

    .header-filters-container {
      width: 100%;
    }

    .cropseason-resolution-container {
      width: 100%;
      z-index: 100;
    }

    .cropseason-dropdown-container {
      width: 100%;
      z-index: 200;
    }

    .cropseason-date-container {
      width: 100%;
      z-index: 150;
    }
  }

  @media only screen and (max-width: $breakpoint-mobile) {
    flex-direction: column;
    .header-filters-container {
      flex-direction: column;
    }

    .track-page-title {
      font-size: 23px;
      flex: 1;
      margin-bottom: 5px;
    }

    .cropseason-dropdown-container {
      width: 100%;
      z-index: 150;
    }

    .cropseason-resolution-container {
      width: 100%;
      align-self: flex-start;
      margin-left: 0px;
      width: 100%;
      z-index: 50;
    }

    .cropseason-date-container {
      width: 100%;
      z-index: 100;
    }
  }

  @media only screen and (width > $breakpoint-tablet) {
    align-items: center;
  }
}
