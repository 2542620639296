@import '~styles/spectre/variables';
@import '~styles/variables';

.search-container {
  position: relative;
  &.search-dark {
    input {
      background-color: #394958;
      border: 1px solid $accent-light-blue;
      color: $background-700;
      outline: 0;
      &::placeholder {
        color: $element-400;
      }
      &:disabled {
        background-color: #394958;
        color: $element-100;
        border: 1px solid $element-100;
        &::placeholder {
          color: $element-100;
        }
      }
    }
  }

  input {
    width: 100%;
    height: 40px;
    font-size: 14px;
    padding-left: 40px;
    border: 1px solid $background-700;
    border-radius: 4px;
  }

  .icon-search {
    position: absolute;
    left: 10px;
    top: 8px;

    path {
      fill: $element-400;
    }
  }

  .disabled-text {
    color: $element-400;
    font-size: 11px;
    margin-top: 5px;
  }

  .icon-close {
    position: absolute;
    right: 10px;
    top: 8px;

    path {
      fill: $element-400;
    }
  }
}
