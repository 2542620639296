@import '~styles/variables';

.waiting-container {
  background-color: #fff;
  width: 100%;
  position: relative;
  padding: 26px 27px;
}

.waiting-faded-header {
  height: 117px;
  background-color: $accent-light-blue;
  border-radius: 4px;
}

.waiting-faded-content {
  display: flex;
  height: calc(100% - 220px);
  overflow: hidden;
}

.waiting-faded-sidebar {
  background-color: $accent-light-gray;
  border-radius: 4px;
  width: 166px;
  margin-top: 27px;
}

.waiting-faded-rows {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0px 0 0 10px;
  width: 100%;
  margin: 60px 0 30px 0;
}
.waiting-faded-row {
  background-color: $accent-light-gray;
  height: 40px;
  min-height: 40px;
  margin: 40px 0 0 20px;
}
.progress-bar-container {
  position: absolute;
  background: $element-400;
  box-shadow: 0px 4px 10px rgba(130, 147, 166, 0.3);
  border-radius: 4px;
  width: 376px;
  height: 82px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 15px 25px;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.progress-bar {
  width: 160px;
  height: 5px;
  background-color: #efefef;
  border-radius: 4px;
  margin-top: 10px;
}
.progress-bar-progress {
  background-color: $accent-cyan;
  height: 5px;
  transition: width 1s;
}
