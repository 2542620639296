@import '~styles/variables';

$graph-details-padding: 10px;
$graph-details-padding-mobile: 10px;

.notification-details-graph {
  height: 400px;
}

.notification-details-related-chips {
  display: flex;
  gap: 10px;
  margin-left: $graph-details-padding;
  flex-wrap: wrap;
  margin-bottom: 30px;
  .notification-details-chip {
    margin-left: 0;
    margin-bottom: 0;
  }
  @media only screen and (max-width: $breakpoint-laptop) {
    margin-left: $graph-details-padding-mobile;
  }
}
.notification-details-chip {
  margin-left: 10px;
  margin-bottom: 5px;
  display: inline-block;
  @media only screen and (max-width: $breakpoint-laptop) {
    margin-left: $graph-details-padding-mobile;
  }
}

.related-variables-toggle {
  display: flex;
  vertical-align: middle;
  gap: 10px;
  margin-left: $graph-details-padding;
  align-items: center;
  margin-bottom: 30px;
  width: 100%;
  margin-top: 30px;
  @media only screen and (max-width: $breakpoint-laptop) {
    margin-left: $graph-details-padding-mobile;
  }
}

.notification-details-content {
  padding: 30px 0;
  padding-bottom: 80px;
  border-radius: 11px;
  background-color: #233545;
  @media only screen and (max-width: $breakpoint-laptop) {
    padding-top: 0;
    padding-bottom: 110px;
  }
}

.notification-button-to-analyze {
  border: 1px solid white;
  border-radius: 4px;
  padding: 5px 10px;
  margin-top: 20px;
  display: inline-block;
  display: inline-flex;
  transition: background-color 0.3s ease;
  text-decoration: none;
  .notification-button-to-analyze-text {
    color: #fff;
    text-decoration: none;
  }

  @media only screen and (max-width: $breakpoint-laptop) {
    width: 100%;
    justify-content: center;
  }
  &:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.2);
    text-decoration: none;
  }
  svg {
    fill: #fff;
    margin-left: 10px;
  }
}
