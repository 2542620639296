@import '~styles/variables';
@import '~styles/text-overflow';

.container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 12px;

  .chip {
    max-width: 158px;
    height: 24px;
    background: $element-200;
    border-radius: 20px;
    padding: 3px 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 0;
    align-self: start;

    .icon {
      fill: $element-900;
      width: 18px;
      height: 18px;
    }

    .chips-content {
      display: inline-block;
      width: 100%;
      @include text-overflow;
    }
  }

  .closed-content {
    display: flex;
    justify-content: space-between;
    justify-content: center;
    align-items: center;
    gap: 5px;

    .details {
      color: $accent-cyan;
    }
  }
}

.add-padding {
  padding: 8px 0;
}
