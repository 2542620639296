@import '~styles/variables';

.spinner-overlay {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .spinner-container {
    position: relative;
  }

  .logo-icon {
    transform: scale(0.5);
  }

  .loading-icon {
    animation: rotating 1s linear infinite;
    position: absolute;
    stroke: $element-900;
    left: 31px;
    top: 31.5px;
  }

  @keyframes rotating {
    from {
      transform: scale(4) rotate(0deg);
    }
    to {
      transform: scale(4) rotate(360deg);
    }
  }
}
