@import '~styles/variables';

.preview-table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 50px;
  margin-bottom: 30px;
  font-size: 18px;
  gap: 80px;

  @media only screen and (max-width: $breakpoint-tablet) {
    margin-top: 27px;
  }

  @media only screen and (max-width: $breakpoint-mobile) {
    margin: 20px 0;
  }
}

.plans-table-container {
  overflow: scroll;
  position: relative;

  .graph-table {
    width: fit-content;
    .graph-table-head {
      top: 0;
      z-index: 1;
      background-color: $element-900;
      position: sticky;
      .graph-table-head-io-row {
        color: $background-100;
        border: 0;
        .graph-table-head-io-data {
          border: 0;
          font-size: 24px;
          padding-bottom: 50px;
        }
      }
      border-collapse: collapse;
      .graph-table-io-header-row {
        color: $background-100;
        min-height: 100px !important;

        .graph-table-io-header-spacer {
          border: 0;
        }
      }
      .graph-table-head-row {
        color: $background-100;

        .graph-table-week-data {
          padding-bottom: 50px;
          .unit {
            position: absolute;
            color: $element-200;
            font-size: 12px;
            font-weight: 700;
            bottom: 25px;
            z-index: -1;
          }
        }

        .graph-table-week-header {
          color: $element-900;
          background-color: $background-500;
          border-radius: 4px 4px 0 0;
          height: 80px;
          position: sticky;
          background-clip: padding-box;
          left: 0;
          text-align: center;
          transform: translateY(2px);
          font-size: 14px;
          width: 68px;
          &:before {
            content: '';
            position: absolute;
            left: 0;
            top: -12px;
            width: 100%;
            border: 6px solid white;
          }
          &:after {
            content: '';
            position: absolute;
            bottom: 0;
            right: 0;
            background: $element-100;
            width: 68px;
            height: 1px;
          }
        }

        .graph-table-header-spacer {
          width: 60px;
          border-bottom: 0;

          .graph-table-week-data {
            color: $background-100;
            border-style: hidden;
            display: flex;
          }
        }
      }
    }
    .graph-table-body {
      .graph-table-body-row {
        .graph-table-body-data {
          color: $background-100;
          border-style: hidden;
          min-width: 120px;
        }

        .graph-table-body-data-timestamp {
          min-width: 68px;
          color: $element-900;
          text-align: center;
          font-weight: bold;
          background-color: $background-500;
          border-top: 1px solid $element-100;
          position: sticky;
          left: 0;
          &:after,
          &:before {
            content: '';
            position: absolute;
            left: 0;
            width: 100%;
          }
          &:before {
            top: -1px;
            border-bottom: 1px solid $element-100;
          }
        }
        .graph-table-body-spacer {
          min-width: 60px;
          border: 0;
          background-color: $element-900;
        }
        &:last-child {
          .graph-table-body-data-timestamp {
            border-radius: 0 0 4px 4px;
          }
        }
      }
    }
  }
}
