@import '~styles/variables';

.profile {
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;

  .profile-name {
    font-size: 14px;
    font-weight: 700;
    color: $element-900;

    .account-name {
      font-size: 10px;
      color: $element-100;
    }
  }

  .profile-header {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
  }

  .accordion-item {
    margin-left: 35px;
  }

  .profile-image {
    width: 40px;
    height: 40px;
    margin-right: 5px;
  }

  .profile-body {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    color: $element-200;
    gap: 25px;
    margin-left: 5px;

    .btn-invite {
      border: 1px solid $element-200;
      color: $element-200;
      width: 160px;
      height: 40px;

      @media only screen and (max-width: $breakpoint-mobile) {
        width: 100%;
      }
    }

    .switch-account {
      display: flex;
      justify-content: space-between;
    }
  }

  .avatar-mobile {
    margin-right: 5px;
    background: $background-500;
  }

  hr {
    margin-top: 27px;
    border: 1px solid $background-500;
    width: 100%;
  }
}
