@import '~styles/variables';

.ripening-row-full {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
}

.ripening-row {
  display: flex;
  justify-content: center;
  gap: 20px;
  position: relative;
  margin-bottom: 40px;
}

.ripening-period {
  margin-top: 30px;
}

.ripening-period-warning {
  background-color: #8293a6;
  color: #fff;
  padding: 20px 40px;
  border-radius: 6px;
  position: absolute;
  max-width: 370px;
}

.ripening-data-hide {
  opacity: 0;
}

.ripening-content {
  display: flex;
  gap: 20px;
}

.ripening-error {
  color: $accent-red;
  margin-top: 30px;
  max-width: 200px;
}
