@import '~styles/variables';

.modal-component .modal {
  .modal-container {
    background-color: $background-500;
    padding: 4px;
    border-radius: 8px;
    font-size: 12px;
    .input {
      width: 100%;
    }
  }

  .modal-header {
    color: $element-900;
    padding-bottom: 0;
  }

  .modal-body {
    padding: 0 16px;
  }

  .modal-title {
    font-weight: 700;
  }

  .close-icon {
    fill: $element-400;
    cursor: pointer;
  }

  .modal-footer {
    display: flex;
    justify-content: flex-end;

    .btn {
      margin-left: 10px;
    }

    .reset-btn {
      display: flex;
      align-items: center;
      margin-right: auto;
      justify-content: flex-end;
      margin-left: 0px;
      .icon-white {
        margin-right: 10px;
      }
    }
  }

  &:target,
  &.active {
    .modal-overlay {
      background: rgba($background-100, 0.8);
      backdrop-filter: blur(2px);
    }
  }
}
