@import '~styles/variables';

$highlighted: #33c9ff;

.main-container {
  padding-bottom: 18px;

  .header {
    max-width: 120px;
    margin-bottom: 2px;
    color: $element-400;
    font-size: 11px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &.isHighlighted {
    .input-container {
      border: 2px solid $highlighted;
      .controls-container {
        border-color: $highlighted;
        .increment-container {
          border-color: $highlighted;
        }
      }
    }
  }
  .input-container {
    display: flex;
    width: 120px;
    height: 40px;
    border: 1px solid $background-700;
    border-radius: 4px;

    .value-container {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      background-color: $background-500;
      color: $element-900;
      font-size: 14px;
      flex: 1;
      .NA {
        color: $element-600;
      }

      width: 97px;
      outline: none;
      border: 0;
      text-align: center;
    }

    .controls-container {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      background-color: $background-200;
      width: 24px;
      display: flex;
      flex-direction: column;
      border-left: 1px solid $background-700;

      .increment-container {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid $background-700;
      }

      .decrement-container {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  &.main-container-small {
    padding-bottom: 0;
    .input-container {
      width: 90px;
    }
    .value-container {
      width: 67px;
    }
    .header {
      width: 90px;
      white-space: pre-wrap;
    }
  }
}

.input-container-small {
  .input-container {
    width: unset;
    height: unset;

    .value-container {
      max-width: 57px;
      height: 27px;
    }

    .controls-container {
      max-height: 27px;
    }

    .increment-container {
      max-height: 13.5px;
    }

    .decrement-container {
      max-height: 13.5px;
    }
  }
}

.main-container-disabled {
  pointer-events: none;

  .input-container {
    border-color: $element-200;

    .value-container {
      color: $element-200;
    }

    .controls-container {
      border-color: $element-200;

      .increment-container {
        border-color: $element-200;
      }

      .disabled-icons {
        fill: $element-200;
      }
    }
  }
}
