@import '~styles/spectre/variables';
@import '~styles/variables';

.crop-season-preview-container {
  display: inline-block;

  .crop-season {
    display: inline-block;
    @media screen and (max-width: $breakpoint-laptop) {
      margin-top: 0;
      font-size: 14px;
    }
  }

  .name {
    margin: 0 25px 0 10px;
  }
}
