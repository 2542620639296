@import '~styles/variables';

.temp-strategy-columns {
  display: flex;
  justify-content: space-between;
  height: 100%;
  align-items: flex-end;
  margin-bottom: 15px;
}
.temp-strategy-columns-short {
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
}

.temp-strategy-date {
  position: relative;
  .error-message {
    margin: 0;
    padding: 0;
    color: $accent-red;
    font-size: 11px;
    margin-left: 0;
    margin-top: 0;
    margin-bottom: 10px;
    position: absolute;
    width: 200px;
  }
}

.temp-strategy {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  gap: 10px;
  height: 40px;
  &.temp-strategy-first-row {
    margin-right: 45px;
  }
}
.close-icon-container {
  width: 24px;
  transform: translateY(3px);

  .close-icon {
    &:hover {
      fill: white;
    }
  }
}

.flexible-temperature-container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
}
