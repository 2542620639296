@import '~styles/variables';

.legend-item {
  position: relative;
  display: flex;
  align-items: center;
  gap: 5px;

  .line {
    width: 30px;
    height: 3px;
  }

  .info-icon {
    display: flex;
    align-items: center;
    height: 10px;
  }
  &.legend-item-disabled {
    cursor: pointer;
    path {
      stroke: $element-200;
    }
    span {
      color: $element-200;
    }
    .info-icon {
      height: 25px;
    }
  }
}
