@import '~styles/variables';

.container {
  width: 100%;
  height: 70px;
  background: $background-200;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 10px 20px;

  .settings-toggle {
    margin-right: 10px;
    line-height: 0;
  }

  .icon {
    margin-left: 20px;
    margin-right: 5px;
    transform: translateY(2px);

    svg {
      fill: $background-700;
    }
  }

  &:hover:not(.non-editable):not(.container-disabled) {
    background: $background-500;

    .button-container {
      display: flex;
    }
  }

  .button-container {
    margin-left: auto;
    display: none;
    gap: 10px;

    .reset {
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        margin-right: 5px;
      }
    }

    .apply-button {
      background-color: $accent-cyan;
      border-color: $accent-cyan;

      span {
        display: flex;
        svg {
          transform: translateY(2px);
          margin-right: 5px;
        }
      }
    }

    .apply-button-disabled {
      background: $background-700;
      color: $element-400;
      pointer-events: none;
      border-color: $background-700;
    }
  }

  .text-content {
    align-items: center;
    display: flex;
    font-size: 14px;
    color: $background-700;

    .input-container {
      display: flex;
      flex-direction: column;
      .dropdown {
        width: 200px;
        height: 30px;
        margin-left: 10px;
      }
      .input {
        display: block;
        margin: 0px 7px;
        margin-top: 10px;
        height: fit-content;
        transform: translateY(10px);
        width: fit-content;
      }

      .error-text {
        height: 11px;
        color: $accent-red;
        font-size: 11px;
        padding-left: 8px;
        transform: translateY(-8px);
      }
    }
  }
}

.container-disabled {
  border: 1px solid $element-200;

  .icon {
    svg {
      fill: $element-200;
    }
  }

  .text-content {
    color: $element-200;
  }
}
