@import '~styles/variables';

@mixin styledButton($normal-background, $normal-color, $active-background, $active-color) {
  .btn {
    background-color: $normal-background;
    color: $normal-color;

    .icon-svg {
      fill: $normal-color;
    }

    .icon-stroke {
      stroke: $normal-color;
    }
  }

  .active {
    background-color: $active-background;
    color: $active-color;

    .icon-svg {
      fill: $active-color;
    }

    .icon-stroke {
      stroke: $active-color;
    }
  }
}

.switch-container {
  .btn {
    border-color: $background-600;
    border-radius: 4px;
    padding: 5px;
    height: 36px;
  }
  &.dark {
    @include styledButton($background-100, $element-400, $accent-cyan, $background-100);
  }
  &.light {
    @include styledButton($background-700, $background-600, $accent-cyan, $background-700);
  }
}
