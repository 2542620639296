//Page with header title and filters mixins
@mixin page-container {
  width: 100%;
  margin: 25px;
  height: calc(100% - 50px);
  @media only screen and (max-width: $breakpoint-laptop-large) {
    margin: 0;
  }
}

@mixin error-text {
  display: block;
  margin-top: 5px;
  color: $accent-red;
  font-size: 11px;
}

@mixin page-header {
  margin: 27px 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media only screen and (max-width: $breakpoint-laptop-large) {
    margin: 27px 20px;
  }
}

@mixin side-menu-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border: 1px solid #8293a6;
  border-radius: 4px;
}

@mixin page-title {
  font-weight: 700;
  font-size: 36px;
  margin-right: 20px;
  @media only screen and (max-width: $breakpoint-laptop-large) {
    font-size: 25px;
  }
  @media only screen and (max-width: $breakpoint-tablet) {
    font-size: 20px;
  }
}

@mixin close-icon {
  fill: $element-400;
  width: 24px;
  height: 24px;
  margin-left: auto;
  margin-right: 0;
  cursor: pointer;
}

@mixin page-top-filters {
  display: flex;
  gap: 20px;
  align-items: flex-start;
}

@mixin page-top-filters-mobile {
  display: flex;
  align-items: stretch;
  gap: 0;
  flex-direction: column;
}

@mixin page-top-filters-cropseasons {
  width: 265px;
  margin-top: 3px;
}
