@import '~styles/variables';

.container {
  width: 60%;
  max-width: 557px;
  .title {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 30px;
  }
  .link-disabled-text {
    color: #8293a6;
    cursor: pointer;
    text-decoration: underline;
  }

  .option {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    justify-content: flex-start;

    .option-row {
      margin-right: 20px;
      width: 100%;
    }
    .option-title {
      font-size: 14px;
      font-weight: 600;
      width: 50%;
    }

    .option-description {
      font-size: 12px;
      font-weight: 400;
      flex-basis: 0.5;
    }

    .option-description-disabled-info {
      color: #8293a6;
      font-size: 12px;
    }

    .option-toggle {
      margin-left: auto;
    }
  }

  @media only screen and (width <= $breakpoint-mobile) {
    width: 100%;
    padding: 0 20px;
  }
}
