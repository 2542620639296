@import '~styles/spectre/variables';
@import '~styles/variables';

.analyze-view-container {
  width: 100%;
  display: flex;
  .analyze-container {
    padding: 10px 36px 32px 27px;
    min-width: calc(100% - 400px);
    overflow: auto;
    height: 100%;
    width: 100%;
    @media only screen and (width < $breakpoint-laptop) {
      padding: 30px 20px;
    }
    .toggle-disabled {
      pointer-events: none;
      color: $element-400;
    }

    .accordion-body {
      .element-text {
        font-size: 14px;
        font-weight: 400;
      }
    }

    .analyze-graph-container {
      border-radius: 8px;
      background: $background-200;
      position: relative;
      z-index: 0;
      overflow-x: hidden;
      @media only screen and (width < $breakpoint-laptop) {
        background-color: $background-100;
      }
    }

    .header {
      display: flex;
      justify-content: space-between;

      .crop-seasons {
        font-size: 14px;
      }

      .analyze-header-filters {
        display: flex;
        gap: 10px;
        align-items: center;
        vertical-align: middle;
        .analyze-header-toggle-notifications {
          display: flex;
          align-items: center;
          gap: 10px;
          margin-right: 20px;
          cursor: pointer;
          @media screen and (max-width: $breakpoint-tablet) {
            display: none;
          }
          .analyze-header-toggle-notifications-text {
            font-size: 14px;
            font-weight: 400;
            color: #8293a6;
          }
        }
      }
    }

    .title {
      font-weight: bold;
      font-size: 36px;
      margin: 20px 0px 40px 0px;

      @media only screen and (width < $breakpoint-laptop) {
        margin: 20px 0px 25px 0px;
      }
    }

    .crop-seasons {
      margin: 30px 0;
    }

    .graph {
      width: 100%;
      height: 50vh;
      border-radius: 8px;
      min-height: 500px;
      padding: 30px 30px;
      .header-pattern-legend {
        padding-bottom: 20px;
      }

      @media only screen and (width < $breakpoint-laptop) {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  .settings-sidebar-active {
    width: 380px;
    @media only screen and (max-width: $breakpoint-laptop) {
      display: none;
    }
    animation: appear 0.2s;
  }

  .settings-sidebar-inactive {
    width: 0px;
  }
}

@keyframes appear {
  0% {
    width: 0;
  }
  100% {
    width: 270px;
  }
}
