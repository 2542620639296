@import '~styles/variables';

.datepicker-selector {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 35px;
  height: 50px;
  background: $background-500;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  .icon {
    width: 26px;
    height: 26px;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .icon-fill {
    fill: $element-900;
  }
}
