.file-input-upload::file-selector-button {
  display: none;
}

.file-input-upload {
  height: 40px;
}

.file-label {
  background-color: #00bcff;
  color: #000;
  border: 0px;
  border-right: 1px solid #00bcff;
  padding: 10px 15px;
  margin-right: 20px;
  transition: 0.5s;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  border-radius: 4px 0px 0px 4px;
  position: absolute;
  left: 0;
  width: 150px;
}

.input-file-upload-container {
  border: 1px solid #e7f0f3;
  background-color: #394958;
  color: #e7f0f3;
  font-size: 12px;
  border-radius: 4px;
  min-width: 450px;
  margin-bottom: 20px;
  height: 40px;
  position: relative;
  input[type='file'] {
    border-radius: 4px;
    width: 100%;
    opacity: 0;
  }
}

.file-upload {
  text-align: center;
}

.file-input-label {
  height: 100%;
  width: 100%;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  color: #e7f0f3;
  transition: 0.5s;
  position: absolute;
  left: 160px;
  top: 8px;
  width: 270px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
}
