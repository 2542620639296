// Menus
.menu {
  @include shadow-variant(0.05rem);
  list-style: none;
  margin: 0;
  z-index: $zindex-3;

  &.menu-nav {
    background: transparent;
    box-shadow: none;
  }

  .menu-item {
    margin-top: 0;
    padding: 0 $unit-2;
    position: relative;
    text-decoration: none;

    & > a {
      border-radius: $border-radius;
      color: inherit;
      display: block;
      margin: 0 (-$unit-2);
      padding: $unit-1 $unit-2;
      text-decoration: none;
      &:focus,
      &:hover {
        background: $secondary-color;
        color: $primary-color;
      }
      &:active,
      &.active {
        background: $secondary-color;
        color: $primary-color;
      }
    }

    .form-checkbox,
    .form-radio,
    .form-switch {
      margin: $unit-h 0;
    }

    & + .menu-item {
      margin-top: $unit-1;
    }
  }

  .menu-badge {
    align-items: center;
    display: flex;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;

    .label {
      margin-right: $unit-2;
    }
  }
}
