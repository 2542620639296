@import 'spectre/spectre-icons.scss';

.icon-fill {
  fill: $element-500;
  width: 24px;
  height: 24px;
}

.icon-stroke {
  stroke: $element-500;
  width: 24px;
  height: 24px;
}

.icon-light {
  fill: $background-700;
}

.icon-small {
  fill: $element-500;
  width: 20px;
  height: 20px;
}

.icon-white {
  fill: $element-900;
  width: 20px;
  height: 20px;
}
.icon-white-large {
  fill: $element-900;
  width: 24px;
  height: 24px;
}

.icon-dark {
  fill: $background-200;
  width: 20px;
  height: 20px;
}

.icon-pagination {
  fill: $background-100;
  width: 20px;
  height: 20px;
}

.icon-primary {
  fill: $accent-cyan;
  width: 24px;
  height: 24px;
}
