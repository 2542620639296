@import '~styles/variables';

.graph-container {
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;
  gap: 1%;

  .line-graph-container {
    width: 49.5%;
    margin: 1% 0px;
    display: flex;
    border-radius: 8px;
    min-height: 500px;
    background: $background-200;
    box-shadow: 0px 3px 35px rgba(2, 28, 43, 0.27);
    justify-content: center;
    .graph {
      width: 90%;
      height: 65%;
    }
    &.line-graph-warning {
      border-bottom: 20px solid #ff8947;
    }

    @media only screen and (max-width: $breakpoint-mobile) {
      width: 100%;
    }
    @media only screen and (min-width: $breakpoint-card-large-screen) {
      height: 550px;
    }
    @media only screen and (min-width: $breakpoint-card-Xlarge-screen) {
      height: 650px;
    }
  }
}
