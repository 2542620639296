@import '~styles/variables';

.container {
  display: flex;
  gap: 10px;

  .option {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 24px;
    width: fit-content;
    border-radius: 20px;
    border: 1px solid $element-400;
    padding: 4px 10px;
    font-size: 12px;
    color: $element-400;
  }

  .active {
    color: $element-900;
    border-color: $accent-cyan;
    background: $background-500;
  }
}
