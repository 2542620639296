@import '~styles/variables';

.menu-links {
  position: relative;
  width: 240px;
  background: $background-200;
  height: calc(100vh - $navBarHeight);
  top: $navBarHeight;
  transition: width 0.3s;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding-top: 10px;

  a {
    &:focus {
      all: unset;
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }

  @media only screen and (max-width: $breakpoint-mobile) {
    overflow: auto;
    width: 100vw;
    top: 0;
    height: 100vh;
  }

  @media only screen and (width > $breakpoint-mobile) and (max-width: $breakpoint-laptop) {
    width: 360px;
    top: 0;
    height: 100vh;
  }

  .logo {
    display: flex;
    align-items: center;
    margin: 10px 0px 50px 2px;
    padding: 0 10px;

    .logo-svg {
      display: flex;
      align-items: center;
    }

    .logo-name {
      transition: opacity 0.3s;
      opacity: 1;
      width: 220px;
    }

    .icon-logo {
      width: 30px;
      height: 30px;
    }

    div {
      margin-left: 5px;
      white-space: nowrap;
      font-weight: 700;
      span {
        font-weight: 100;
        display: inline-block;
        margin-left: 1px;
      }
    }

    .avatar {
      min-width: 30px;
      height: 30.2px;
    }

    @media only screen and (max-width: $breakpoint-laptop) {
      display: none;
    }
  }
  .menu-item {
    overflow: hidden;
  }

  .accordion-links {
    animation: appear 0.3s ease-in;
    transition: width 5s;
    input:checked ~,
    &[open] {
      & .accordion-body {
        max-height: 50rem;
        padding: 10px 2px;

        span {
          margin-left: 10px;
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 22.5px;
          color: #ffffff;
        }

        .accordion-item {
          display: flex;
          align-items: center;
          height: 46px;

          .accordion-item-icon {
            display: flex;
            align-items: center;
            margin-left: 10px;
          }
          &:hover {
            color: inherit;
            text-decoration: inherit;
            background: $background-600;
            border-radius: 4px;

            .icon-fill {
              fill: $accent-cyan;
            }

            .icon-stroke {
              stroke: $accent-cyan;
            }
          }
        }
      }
    }

    .accordion-header {
      position: relative;
      display: flex;
      justify-content: flex-start;
      gap: 10px;
      font-weight: 700;
      font-size: 11px;
      line-height: 13px;
      text-transform: uppercase;
      color: $element-400;
      padding: 0;
      margin: 10px 10px 0px 10px;
    }
  }

  .close-button {
    display: flex;
    justify-content: flex-end;
    margin: 20px 20px 30px 0px;

    .icon-close {
      fill: $element-400;
    }
  }
  .active-route-link {
    .accordion-item {
      background: $background-600;
      border-radius: 4px;

      .icon-fill {
        fill: $accent-cyan;
      }

      .icon-stroke {
        stroke: $accent-cyan;
      }
    }
  }

  .inactive-route-link,
  .active-route-link {
    color: inherit;
    text-decoration: inherit;
  }
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.inactive {
  .menu {
    width: 0px;
    .menu-item {
      display: flex;
      align-items: center;
      width: 40px;
      height: 40px;
      overflow: hidden;
      margin: 10px 0px 20px 10px;

      .icon-fill {
        animation: appear 0.3s ease-in;
        opacity: 1;
      }

      &:hover {
        background: $background-600;
        border-radius: 4px;

        .icon-fill {
          fill: $accent-cyan;
        }

        .icon-stroke {
          stroke: $accent-cyan;
        }
      }
    }

    .logo-name {
      transition: width 0.3s;
      transition: opacity 0.3s;
      opacity: 0;
      width: 0px;
    }
    .active-route-link {
      .menu-item {
        background: $background-600;
        border-radius: 4px;

        .icon-fill {
          fill: $accent-cyan;
        }

        .icon-stroke {
          stroke: $accent-cyan;
        }
      }
    }

    .inactive-route-link,
    .active-route-link {
      color: inherit;
      text-decoration: inherit;
    }
  }
}

.menu-links-container {
  height: 100%;
  box-shadow: 1px 4px 27px rgba(0, 0, 0, 0.35);
  .side-navigation-button {
    z-index: 1000;
    text-align: center;
    position: absolute;
    width: 24px;
    height: 24px;
    left: 225px;
    top: 58px;
    border: 0;
    background: $background-500;
    transition: left 0.3s;
  }
  .accordion .accordion-body {
    transition: none;
  }

  &.inactive {
    .side-navigation-button {
      left: 65px;
      transition: left 0.3s;
    }
  }
}

.active-icon {
  transform: rotate(180deg);
  transition: transform 0.3s;
}
