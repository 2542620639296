// Chips
.chip {
  align-items: center;
  border-radius: 5rem;
  display: inline-flex;
  font-size: 14px;
  height: 24px;
  line-height: 12px;
  margin: $unit-h;
  max-width: $control-width-sm;
  overflow: hidden;
  padding: 5px 5px 5px 7px;
  text-decoration: none;
  text-overflow: ellipsis;
  vertical-align: middle;
  white-space: nowrap;
  border: 1px solid $light-color;

  &.active {
    color: $light-color;
  }

  .avatar {
    margin-left: -$unit-2;
    margin-right: $unit-1;
  }

  .btn-clear {
    border-radius: 50%;
    transform: scale(0.75);
  }
}
