@import '~styles/variables';

@mixin accordion-body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 6px;
  box-shadow: 0px 3px 10px rgba(26, 26, 26, 0.15);
  margin-top: 5px;
  background: $element-900;
  color: $background-100;
  padding: 10px 2px;
  width: 200px;
  margin-left: auto;
  overflow-y: scroll;
  max-height: 500px;
}

.profile-accordion {
  .profile-body {
    display: none;
  }

  .btn-invite {
    color: $background-500;
    font-size: 12px;
    border: 1px solid $background-500;
    width: 98px;
    margin-left: 20px;
    margin-top: 15px;
    margin-bottom: 12px;
  }

  .invite-separator {
    border: 1px solid $element-200;
    margin: 15px 15px;
  }
  input:checked ~,
  &[open] {
    & .accordion-header > {
      .rotate-180 {
        transform: rotate(180deg);
      }
    }

    & .profile-body {
      @include accordion-body;
    }
  }
  .profile-name {
    font-weight: bold;
    margin-right: 15px;

    .account-name {
      font-size: 10px;
      color: $element-100;
      display: flex;
      justify-content: flex-end;
    }
  }
  .account {
    display: flex;
    align-items: center;

    .account-icon {
      transform: rotate(180deg);
      margin-left: 25px;
    }

    .account-body {
      display: none;
    }

    input:checked ~,
    &[open] {
      & .accordion-header > {
        .rotate-360 {
          transform: rotate(360deg);
        }
      }
      .account-body {
        @include accordion-body;
        position: absolute;
        top: 95px;
        right: 248px;
        width: 170px;
      }

      .accounts {
        width: 300px;
        right: 235px;
      }
    }
  }
  .profile-item {
    z-index: 1000;
    font-weight: 400;
    font-size: 14px;
    padding-left: 20px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .current-id {
      margin-right: 15px;
    }

    &:hover {
      background: $background-700;
    }

    &-small {
      min-height: 30px;
    }
  }
  .header-profile {
    display: flex !important;
    align-items: center;
    flex-direction: row !important;
    justify-content: space-between;
    padding: 0px !important;
    //TODO remove accordin class names from header profile since there is no accordion here and styles are owerwritten
    &.accordion-header {
      color: #132a37;
      font-weight: 400;
      font-size: 14px;
      text-transform: none;
      .profile-name {
        font-size: 0.88rem;
        font-style: normal;
        color: #fff;
      }
    }

    .profile-icon {
      color: $element-400;
    }
  }

  .profile-image {
    width: 40px;
    height: 40px;
    object-fit: cover;
    margin-right: 30px !important;
    background: $background-500;
  }
}
