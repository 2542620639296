@import '~styles/variables';
@import '~styles/text-overflow';

.dropdown-container {
  width: 100%;

  .dropdown {
    width: 100%;
    min-height: 40px;
    border-radius: 4px;
    border: 1px solid $background-700;

    &.active .dropdown-button {
      background: $background-100;
    }

    .dropdown-button {
      min-height: 40px;
      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse;
      align-items: center;
      padding: 0px 10px;
      background: $background-500;

      border-radius: 4px;
      color: $background-700;

      font-size: 14px;
      font-weight: 400;
      .icon-svg {
        fill: $background-700;
        flex-shrink: 0;
      }

      .icon-container {
        align-self: start;
        margin-top: 8px;
      }
    }

    &.active .light {
      background: $element-900;
      color: $background-100;
    }

    .menu {
      padding: 0px;
      max-height: 300px;
    }

    .dropdown-menu {
      margin-top: 10px;
      background: #fff;
      width: 100%;
      color: $background-200;
      border-radius: 6px;
      box-shadow: 0px 3px 10px rgba(26, 26, 26, 0.15);

      .search {
        padding: 10px;
      }

      input {
        border: 1px solid $background-700;

        &:active,
        &:focus {
          outline: none;
          border-color: $background-600;
        }
      }
    }

    .list-item-container {
      display: flex;
      align-items: center;
      width: 100%;

      .dropdown-item {
        @include text-overflow;
        padding: 8px 10px;
        width: 100%;
        color: $background-200;
        font-size: 14px;
        overflow: hidden;
        display: inline-flex;

        &:hover {
          color: $background-200;
          background: $background-700;
        }
      }

      .selected-item {
        background: $accent-cyan;
        color: $element-900;
      }
    }

    .rotate-180 {
      transform: rotate(180deg);
      transition: transform 0.25s;
    }
    .dropdown-icon {
      transform: rotate(0deg);
      transition: transform 0.25s;
    }

    .icon-light-mode {
      fill: $background-100;
    }
  }

  .light {
    border-color: $element-100;
  }
}
