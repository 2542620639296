@import '~styles/variables';

.lines-pattern-legend-text {
  margin-left: 10px;
  font-size: 12px;
  font-weight: 400;
  color: $element-400;
}

.lines-pattern-legend-item {
  display: flex;
  justify-content: center;
  align-items: center;
  .info-icon {
    height: 25px;
    margin-left: 5px;
  }
}

.lines-pattern-legend-item + .lines-pattern-legend-item {
  margin-left: 20px;
}
