@import '~styles/variables';

.slider-values {
  margin-top: 5px;
  display: flex;
  justify-content: space-between;

  .value {
    font-size: 11px;
    color: $background-100;
  }
}
