@import '~styles/spectre/variables';
@import '~styles/variables';
@import '~styles/mixins';

.side-slide {
  overflow: auto;
  min-height: 100vh;
  position: relative;
  height: 100%;
}

.analyze-sidebar-container {
  .expand {
    padding: 0;
    @include side-menu-button;
    .icon-fill {
      fill: #919aa2;
      width: 24px;
      height: 24px;
    }
  }
  .analyze-group-per-hour-with-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    margin-top: 10px;
    .info-icon {
      height: 25px;
    }
    .analyze-group-per-hour {
      display: flex;
      vertical-align: middle;
      gap: 10px;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .analyze-group-per-hour-text {
        font-size: 14px;
        color: $accent-light-blue;
      }

      &.analyze-group-per-hour-disabled {
        pointer-events: none;
        opacity: 0.5;
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
    }
  }

  .top-section,
  .bottom-section {
    display: flex;
    flex-direction: column;
    margin: 0 20px 15px 20px;

    .subtitle {
      margin: 10px 0 20px 0;
      font-size: 18px;
    }

    .dropdown {
      margin-bottom: 10px;
    }

    .dropdown-container {
      margin-top: 10px;
    }
    .multi-select {
      padding-top: 3px;
    }

    hr {
      margin: 10px 0 20px 0;
      width: 100%;
      border: none;
      border-bottom: 1px solid $background-500;
    }

    .resolution-error {
      margin-top: -15px;
      color: $accent-red;
      font-size: 12px;
    }
    .date-container > div {
      width: 100%;
    }
  }

  .accordions-container {
    padding-bottom: 500px;
    scroll-behavior: smooth;
  }

  .collapse {
    align-self: flex-end;
    font-weight: 700;
    font-size: 14px;
    color: $element-800;
    padding-top: 20px;
    cursor: pointer;
  }
  .element-text {
    max-width: 205px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
  }
}
