.plans-comparison {
  width: 100%;
}

.table-header-conainer {
  display: flex;
  position: sticky;
  top: 385px;
  background: #fff;
  z-index: 1;
  padding-top: 20px;
}

.graph-container {
  position: sticky;
  top: 0px;
  z-index: 1;
}

.plans-comparison-plans-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.plan-comparison-buttons {
  display: flex;
}

.plan-comparison-row {
  justify-content: space-between;
  color: #000;
  align-items: center;
  width: 100%;
  display: flex;
  &.comparison-flex {
    display: flex;
  }
  .element-value {
    width: 300px;
    display: flex;
  }
  .row-element {
    display: flex;
  }
  .element-value-name {
    font-weight: 700;
    text-transform: capitalize;
    margin-left: 8px;
    line-height: 26px;
  }
}
.plan-comparison-buttons-text {
  font-weight: 700;
  font-size: 14px;
  color: #132a37;
  margin-right: 10px;
  margin-bottom: 10px;
}
.plans-comparison-plan0 {
  width: 100%;
}
.plans-comparison-plan1,
.plans-comparison-plan2,
.plans-comparison-plan3 {
  width: 310px;
}
