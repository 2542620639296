@import '~styles/variables';

.main-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;

  .co2-container {
    display: flex;

    align-items: center;
    flex-direction: column;
    .amount-row {
      display: flex;
      align-items: center;
      gap: 20px;
      .amount-title {
        width: 200px;
        font-size: 14px;
      }
      .amount {
        width: 120px;
      }
    }
    margin-bottom: 10px;
  }
}
