@import '~styles/variables';

.mumus {
  position: fixed;
  top: 70px;
  right: 0;
  height: 100vh;
  transition: transform 0.2s;
  z-index: 1;
  transform: translateX(300px);

  &.active {
    transform: translateX(0);
  }

  .side-slide-container {
    height: 100%;
    position: relative;
    float: right;
    width: 300px;
    box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.35);
    background-color: $background-200;
    overflow: auto;
    margin-left: 20px;

    .side-slide-header {
      position: sticky;
      top: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px;
      font-weight: 700;
      font-size: 12px;
      background-color: $background-200;
      z-index: 1;

      .icon-close {
        width: 20px;
        height: 20px;
        fill: $element-800;
        cursor: pointer;
      }
    }
  }

  @media only screen and (max-width: $breakpoint-mobile) {
    width: 100vw;
    transform: translateX(100%);

    .side-slide-container {
      width: 100vw;
    }
  }
}
