.react-toast.toast-container {
  &.toast-success {
    background-color: #3dba51;
    color: $element-300;
    font-size: 12px;
  }
  &.toast-error {
    background-color: #fb424d;
    color: $element-300;
    font-size: 12px;
  }
  &.toast-loading {
    color: $element-300;
    font-size: 12px;
  }
  .toast-title {
    display: flex;
    gap: 4px;
    margin-bottom: 2px;
    align-items: center;
  }
  .toast-description {
    margin-left: 20px;
  }
  .toast-icon {
    width: 15px;
    height: 15px;
  }
  svg {
    fill: $element-300;
  }
}
