// Text
// Text alignment utilities
.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

// Text transform utilities
.text-lowercase {
  text-transform: lowercase;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

// Text style utilities
.text-normal {
  font-weight: normal;
}

.text-bold {
  font-weight: bold;
}

.text-italic {
  font-style: italic;
}

.text-large {
  font-size: 1.2em;
}

.text-small {
  font-size: .9em;
}

.text-tiny {
  font-size: .8em;
}

.text-muted {
  opacity: .8;
}

// Text overflow utilities
.text-ellipsis {
  @include text-ellipsis();
}

.text-clip {
  overflow: hidden;
  text-overflow: clip;
  white-space: nowrap;
}

.text-break {
  hyphens: auto;
  word-break: break-word;
  word-wrap: break-word;
}

.text-noselect {
  -webkit-user-select: none;  
  -moz-user-select: none;    
  -ms-user-select: none;      
  user-select: none;
}
