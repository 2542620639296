@import '~styles/mixins';
@import '~styles/variables';

$mobileFilterScreenZInex: 1;

.filters {
  @include page-top-filters;
  .location {
    width: 205px;
  }
  .cropseasons {
    @include page-top-filters-cropseasons;
  }
  .date-picker {
    margin-top: 3px;
    width: 220px;
  }
}

.mobileExpandedFilters {
  position: absolute;
  background-color: #132a37;
  width: 100%;
  z-index: $mobileFilterScreenZInex;
  left: 0;
  top: $navBarHeight;
  min-height: calc(100% - $navBarHeight);
  padding: 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  overflow: auto;
  .filters {
    @include page-top-filters-mobile;
  }
  .notification-filter {
    margin-bottom: 10px;
    width: 100%;
  }
}

.mobileExpandedFilters-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.mobileExpandedFilters-close {
  @include close-icon;
}
.mobileExpandedFilters-header-text {
  color: #8293a6;
}

//TODO -use mixin for this
.mobileActionMoreFilters {
  @include side-menu-button;
  cursor: pointer;
  .icon-fill {
    fill: #919aa2;
    width: 24px;
    height: 24px;
  }
}
