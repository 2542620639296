@import '~styles/variables';

.main-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;

  .stem-density-container {
    display: flex;

    align-items: center;
    flex-direction: column-reverse;
    .period-row {
      min-width: 424px;
      display: flex;
      align-items: center;
      gap: 20px;
      .period-title {
        width: 100px;
        font-size: 14px;
      }
      .week {
        margin-top: -40px;
        width: 120px;
      }
      .amount {
        width: 120px;
      }
      .close-icon-container {
        width: 24px;
        transform: translateY(3px);

        .close-icon {
          &:hover {
            fill: white;
          }
        }
      }
      .period-title-from-start-header {
        width: 120px;
      }
      .period-title-from-start {
        text-align: left;
        width: 100px;
      }
      .period-title-until-end {
        margin-top: 10px;
        margin-left: 290px;
      }
    }
    .warning {
      margin: 0;
      padding: 0;
      color: $accent-red;
      font-size: 11px;
      margin-left: 70px;
      margin-top: -10px;
      margin-bottom: 10px;
    }
    .warning-valid-week {
      margin: 0;
      padding: 0;
      color: $accent-red;
      font-size: 11px;
      margin-left: -90px;
      margin-top: -10px;
      margin-bottom: 10px;
    }
    margin-bottom: 10px;
  }
  .add-button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    height: 30px;
    &:hover {
      color: $accent-blue;
      border: 2px solid $accent-blue;
      .plus-icon {
        fill: $accent-blue;
      }
    }
    .plus-icon {
      min-width: 20px;
      min-height: 20px;
    }
  }
}
