@import '~styles/variables';

.strategy-preview-container {
  flex: 1;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 27px 26px 27px;
  z-index: 0;

  .export-container {
    flex-grow: 2;
    display: flex;
    justify-content: flex-end;
  }

  .header-variable {
    margin-right: 10px;
  }

  .strategy-preview-page-name,
  .strategy-preview-page-name-mobile {
    display: none;
    margin-top: 10px;
  }

  .strategy-preview-header {
    display: flex;
    border-bottom: 1px solid $background-500;
    margin-top: 10px;
    .strategy-preview-title-container {
      display: flex;
      align-items: center;
      .strategy-preview-title {
        font-size: 36px;
        margin-left: 20px;
        font-weight: bold;
        margin-right: auto;
      }
    }
    .strategy-preview-crop-information {
      display: flex;
      align-items: center;
      gap: 40px;
      margin-right: 40px;
      margin-left: auto;

      .strategy-preview-crop-variety {
        display: flex;
        gap: 10px;
        color: $element-400;
        &-value {
          color: $element-900;
        }
      }
      .strategy-preview-crop-location {
        color: $element-400;
        display: flex;
        gap: 10px;
        &-value {
          color: $element-900;
        }
      }
    }
    .strategy-preview-actions {
      display: flex;
      align-self: center;
      gap: 10px;
      justify-self: flex-end;
      .strategy-preview-actions-more {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 36px;
        height: 36px;
        border: 1px solid $element-400;
        border-radius: 4px;
      }
    }
    @media only screen and (max-width: $breakpoint-tablet) {
      flex-wrap: wrap;
      .strategy-preview-title-container {
        order: 1;
        .strategy-preview-title {
          margin-top: 10px;
          margin-right: calc(9vw + 10px);
          transform: translateY(8px);
          margin-left: 10px;
          flex-grow: 1;
          @media only screen and (max-width: $breakpoint-tablet) {
            margin-right: 10px;
          }
        }
      }
      .strategy-preview-crop-information {
        margin-right: 0px;
        margin-bottom: 20px;
        order: 3;
      }
      .strategy-preview-actions {
        margin-left: auto;
        order: 2;
      }
    }
    @media only screen and (max-width: $breakpoint-mobile) {
      flex-wrap: wrap;
      .strategy-preview-page-name-mobile {
        display: block;
        font-size: 14px;
        color: $element-400;
        order: 1;
        align-self: center !important;
      }
      .strategy-preview-title-container {
        order: 3;
        .strategy-preview-title {
          margin-top: 10px;
          margin-left: 8px;
          margin-right: 30px;
          font-size: 23px;
          transform: translateY(4px);
        }
      }
      .strategy-preview-crop-information {
        margin-right: 0px;
        margin-bottom: 10px;
        margin-left: 0px;
        order: 4;
        font-size: 12px;
      }
      .strategy-preview-actions {
        order: 2;
        margin-left: auto;
      }
    }
  }

  .line-graph-title {
    margin: 20px 0 30px 0 !important;
    font-weight: 700;
    font-size: 24px;
  }

  .preview-button {
    width: 139px;
    height: 40px;
    align-self: center;
    margin-top: 30px;
  }

  @media only screen and (max-width: $breakpoint-tablet) {
    padding: 0px 20px 0px 20px;
    .strategy-preview-page-name {
      display: block;
      font-size: 14px;
      color: $element-400;
    }
  }
  @media only screen and (max-width: $breakpoint-mobile) {
    padding: 0px 10px 0px 10px;
    .strategy-preview-page-name {
      display: none;
    }
  }
}
.strategy-preview-table-view {
  background-color: $element-900;

  .strategy-preview-header {
    border-bottom: 1px solid $background-700;
  }

  .strategy-preview-back {
    fill: $background-100;
  }
  .strategy-preview-title {
    color: $background-100;
  }
  .strategy-preview-crop-information {
    .strategy-preview-crop-variety {
      .strategy-preview-crop-variety-value {
        color: $background-100;
      }
    }
    .strategy-preview-crop-location {
      .strategy-preview-crop-location-value {
        color: $background-100;
      }
    }
  }
}
