@import '~styles/variables';
@import '~styles/mixins';

.container {
  @include page-container;
  .header {
    @include page-header;
    .title {
      @include page-title;
    }
    .filters {
      @include page-top-filters;
      .cropseasons {
        @include page-top-filters-cropseasons;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
}
