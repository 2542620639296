@import '~styles/variables';

.chip {
  .dot {
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background-color: $accent-cyan;
    margin-right: 4px;
  }
  .chip-icon {
    padding: 0 5px;
  }
  &.is-disabled {
    border-color: #8293a6;
    color: #8293a6;
    .close-icon {
      fill: #8293a6;
    }
  }
}
