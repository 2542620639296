// Navbar
.navbar {
  .navbar-section {
    align-items: center;
    display: flex;

    &:not(:first-child):last-child {
      justify-content: flex-end;
    }
  }

  .navbar-center {
    align-items: center;
    display: flex;
    flex: 0 0 auto;
  }

  .navbar-brand {
    font-size: $font-size-lg;
    text-decoration: none;
  }
}
